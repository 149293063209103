<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a [routerLink]="['/pages/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Clientes ativos</li>
    </ol>
</nav>
<div class="card card-radius mb-3">
    <div class="card-body">

        <div class="form-group">
            <label for="term">Buscar</label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Digite o texto para buscar" [(ngModel)]="term">
            </div>
        </div>
    </div>
</div>
<div class="card card-radius mt-3">
    <div class="card-body">
        <div class="buttons-export d-flex">
            <div class="w-50 d-flex justify-content-start">
                <a (click)="exportNamePhone()" class="btn btn-outline-secondary">Exportar nome e telefone</a>
            </div>
            <div class="w-50 d-flex justify-content-end">
                <a (click)="selectAll()" class="btn btn-outline-secondary">Selecionar todos</a>
                <a (click)="routeViewClients()" class="btn btn-outline-secondary">Visualizar selecionados</a>
            </div>
        </div>
        <div class="table-responsive card-radius">
            <table class="table table-sm table-hover table-striped">
                <thead class="button-color">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Nome</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Telefone</th>
                        <th scope="col">Data do projeto</th>
                        <th scope="col">Premium</th>
                        <th scope="col">Exportar PDF</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of resultList | filter:term | paginate: { itemsPerPage: itensPerPage, currentPage: currentPage }; let i= index">
                        <td><input (change)="selectedClient($event, item.email, item.name, item.phone)"
                                [checked]="isChecked" type="checkbox">
                        </td>
                        <td [ngClass]="item.name ? '' : 'empty-field'">{{item.name ? item.name : '*'}}</td>
                        <td [ngClass]="item.email ? '' : 'empty-field'">{{item.email ? item.email : '*'}}</td>
                        <td [ngClass]="item.phone ? '' : 'empty-field'">{{item.phone ? item.phone : '*'}}</td>
                        <td [ngClass]="item.createdAt ? '' : 'empty-field'">{{item.createdAt ?
                            FormatDate(item.createdAt) : '*'}}</td>
                        <td [ngClass]="item.premium ? '' : 'empty-field'"
                            *ngIf="item.premium">
                            <i class="fas fa-check text-success"></i>
                        </td>
                        <td *ngIf="!item.premium">*</td>
                        <td (click)="exportUserPDF(item.email)" [ngClass]="userHavePdf(item.email) ? '' : 'empty-field'"
                            *ngIf="userHavePdf(item.email)">
                            <em class="fas fa-file-pdf"></em>
                        </td>
                        <td *ngIf="!userHavePdf(item.email)">*</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls (pageChange)="currentPage = $event" previousLabel="Anterior" nextLabel="Próximo">
            </pagination-controls>
        </div>
    </div>
</div>