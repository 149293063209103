import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { UserUpdateResponseDto } from "../dtos/user-update-response.dto";
import { WalletGetResponseDto } from "../dtos/wallet-get-response.dto";
import { WalletRegisterRequestDto } from "../dtos/wallet-register-request.dto";
import { WalletUpdateRequestDto } from "../dtos/wallet-update-request.dto";
import { BaseService } from "./base.service";

@Injectable()
export class WalletService extends BaseService {

    url: string = `${environment.Apis.accessControl}/wallet`;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();
    }

    list(): Observable<WalletGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getById(_id: string): Observable<WalletGetResponseDto> {
        return this.httpClient
            .get(`${this.url}/id/${_id}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    register(dto: WalletRegisterRequestDto): Observable<WalletGetResponseDto> {
        return this.httpClient
            .post(`${this.url}`, dto, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    update(_id: string, dto: WalletUpdateRequestDto): Observable<WalletUpdateRequestDto> {
        return this.httpClient
            .put(`${this.url}/id/${_id}`, dto, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(_id: string): Observable<any> {
        return this.httpClient
            .delete(`${this.url}/id/${_id}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
