import { Component, OnInit } from '@angular/core';
import { CashFlowService } from '../../services/cash-flow.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { WalletService } from "src/app/services/wallet.service";
import { ClientService } from "src/app/services/client.service";
import { CalculateObjectives } from "src/app/utils/calculate-objectives.util";
import { renameKeysAndAddValues } from "src/app/utils/keyTranslations";

@Component({
  selector: 'app-view-customers-exported',
  templateUrl: './view-customers-exported.component.html',
  styleUrls: ['./view-customers-exported.component.scss']
})
export class ViewClientsExportedComponent implements OnInit {

  term: string;

  currentPage: number = 0;

  arrayBoxChecked = [];

  users = [];

  public pageSlice = [];

  countSuccess: number = 0;
  countProjectLife: number = 0;

  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';

  constructor(
    private cashFlowService: CashFlowService,
    private route: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private clientService: ClientService,
  ) {
    if (this.route.getCurrentNavigation().extras.state && this.route.getCurrentNavigation().extras.state.users) {
      this.arrayBoxChecked = this.route.getCurrentNavigation().extras.state.users;
    } else {
      this.route.navigate(['/pages/progress-customers']);
    }
  }

  ngOnInit(): void {
    this.ngxSpinnerService.show();
    this.usersProjectLife();
  }

  usersProjectLife() {
    console.log('this.arrayBoxChecked:',this.arrayBoxChecked)
    this.arrayBoxChecked.forEach(userEmail => {
      this.cashFlowService.get(userEmail).subscribe(
        successCashflow => {
          if (successCashflow && successCashflow.report && successCashflow.report.length > 0) {
            this.clientService.pdfTableScreen(userEmail).subscribe(successTable => {
              this.users.push({
                user: userEmail,
                report: successCashflow.report,
                age: successTable.responseFinancialIndependenceData.data.age,
                yeild: successTable.responseInvestorProfileData.data.yeild,
                numberIF: successTable.responseDashboardData.data.financialIndependenceTotal,
                incomeIF: successTable.responseDashboardData.data.projecaoRendaIndependencia,
                objectives: CalculateObjectives(
                  successTable.responseObjectivesData.data,
                  successTable.responseDashboardData.data.projectStartDate,
                  successTable.responseDashboardData.data.idadeIndependencia,
                  successTable.responseFinancialIndependenceData.data.age
                )
              });
              console.log(this.users)


              if (this.users.length - 1 == this.countProjectLife) {
                this.pageSlice = this.users.slice(0, 1);
                this.ngxSpinnerService.hide();
              }
              this.countProjectLife++;
            }, error => console.log(error))
          }
        },
        error => console.log(error.message)
      )
    });
  }

  exportFluxoFinanceiro() {
    const workbook = XLSX.utils.book_new();

    workbook.Props = {};
    workbook.Props.Title = 'Relatório de Clientes Ativos';

    this.arrayBoxChecked.forEach(userEmail => {
      this.cashFlowService.get(userEmail).subscribe(
        successCash => {
          this.clientService.exportTable(userEmail).subscribe(
            success => {
              if (successCash && successCash.report && successCash.report.length > 0) {
                setTimeout(() => {

                  console.log(successCash.report)

                  console.log(success)

                  const translateKeys = renameKeysAndAddValues(successCash.report, success.currentIncome, success.discoverNumber, success.invesments.appliedValue)

                  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(translateKeys);

                  XLSX.utils.book_append_sheet(workbook, worksheet, userEmail.substr(0, 30));

                  if (this.arrayBoxChecked.length - 1 == this.countSuccess) {
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

                    this.saveAsExcelFile(excelBuffer, `fluxo-financeiro`);

                    this.arrayBoxChecked = [];
                    this.countSuccess = 0;

                    return;
                  }
                  this.countSuccess++;
                }, 300);
              }
            }, error => console.log(error)
          )


        },
        error => console.log(error)
      )
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION);
  }

  onPageChange(event: PageEvent) {

    this.currentPage = event.pageIndex;

    const startIndex = event.pageIndex * event.pageSize;

    let endIndex = startIndex + event.pageSize;

    if (endIndex > this.users.length) endIndex = this.users.length;

    this.pageSlice = this.users.slice(startIndex, endIndex);
  }
}
