<app-topbar></app-topbar>
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Forbidden</li>
    </ol>
</nav>
<div class="text-center">
    <div class="error mx-auto" data-text="403">403</div>
    <p class="lead text-gray-800 mb-5">Forbidden</p>
    <p class="text-gray-500 mb-0"> You cannot access this page</p>
    <a [routerLink]="['/pages/dashboard']">&larr; Back to Dashboard</a>
</div>