import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { LocalStorageKeys, LocalStorageUtil } from '../utils/localstorage-util';

export abstract class BaseService {

    protected LocalStorage = new LocalStorageUtil();

    protected anonymousHeader() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    protected authorizedHeader() {
        const user = this.LocalStorage.get(LocalStorageKeys.user);
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user?.token.accessToken}`
            })
        };
    }

    protected extractResponse(response: any) {
        return response || {};
    }

    protected extractData(response: any) {
        return response.data || {};
    }

    protected serviceError(response: Response | any) {
        let customError: string[] = [];
        let customResponse = { error: { erros: [] } }

        if (response instanceof HttpErrorResponse) {

            if (response.statusText === 'Unknown Error') {
                customError.push('Unknown Error');
                response.error.erros = customError;
            }
        }

        if (response.status === 500) {
            customError.push('Error processing request');

            customResponse.error.erros = customError;
            return throwError(customResponse);
        }

        return throwError(response);
    }
}