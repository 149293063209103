import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientGetResponseDto } from '../../dtos/client-get-response.dto';
import { ClientService } from '../../services/client.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-check-kyc',
  templateUrl: './check-kyc.component.html',
  styleUrls: ['./check-kyc.component.scss']
})
export class CheckKycComponent implements OnInit {

  resultList: ClientGetResponseDto[];

  term: string;

  currentPage: number = 1;
  itensPerPage: number = 20;

  arrayNamePhoneChecked = [];
  isChecked: boolean;

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private clientService: ClientService,
  ) { }

  ngOnInit(): void {
    this.ngxSpinnerService.show();

    this.list();
  }

  list() {
    this.clientService.listWaitActivation().subscribe(
      success => this.runListSuccess(success),
      error => this.runError(error)
    );
  }

  runListSuccess(success: ClientGetResponseDto[]): void {
    this.resultList = success;
    this.ngxSpinnerService.hide();
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
  }

  exportNamePhone() {
    const workbook = XLSX.utils.book_new();

    workbook.Props = {};
    workbook.Props.Title = 'Número e telefone dos clientes ativos';

    workbook.SheetNames.push('Página 1');

    let wscols = [
      { width: 30 }, //col 1
      { width: 30 }, //col 2
    ];

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrayNamePhoneChecked);

    worksheet['!cols'] = wscols;

    workbook.Sheets['Página 1'] = worksheet;

    workbook.Sheets['Página 1']['A1'].v = 'Nome';
    workbook.Sheets['Página 1']['B1'].v = 'Telefone';

    XLSX.writeFile(workbook, 'nome-telefone.xlsx');
  }
  
  selectAll() {
    this.isChecked = true;
    this.resultList.forEach(user => {
      this.arrayNamePhoneChecked.push({ name: user.name, phone: user.phone, email: user.email });
    })
    console.log(this.arrayNamePhoneChecked)
  }

  selectedClient(event, userEmail: string, userName: string, userPhone: string) {
    if (event.target.checked == true) {
      this.arrayNamePhoneChecked.push({ name: userName, phone: userPhone, email: userEmail});
    } else {
      if (this.arrayNamePhoneChecked.length != 0) {
        let indexName = this.arrayNamePhoneChecked.indexOf({ name: userName, phone: userPhone, email: userEmail });
        this.arrayNamePhoneChecked.splice(indexName, 1);
      }
    }
  }
}