import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './nav/not-found/not-found.component';
import { LoginComponent } from './auth/login/login.component';
import { SecurityComponent } from './auth/security/security.component';
import { ForbiddenComponent } from './nav/forbidden/forbidden.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module')
        .then(a => a.PagesModule)
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module')
        .then(a => a.AuthModule)
  },
  {
    
    path: 'forbidden', component: ForbiddenComponent
  },
  {
    
    path: '**', component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
