import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserGetResponseDto } from '../../dtos/user-get-response.dto';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  type: string = '';
  textButton: string = 'Editar';

  users: UserGetResponseDto[];
  formSearch: FormGroup;

  currentPage: number = 1;
  itensPerPage: number = 20;

  term: string;

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.formSearch = this.fb.group({
      term: ['']
    })
  }

  ngOnInit(): void {
    this.ngxSpinnerService.show();
    this.route.params.subscribe(
      params => {
        this.type = params['type'];
        switch (this.type) {
          case 'all': this.textButton = 'Edit'; break;
          case 'reset-password': this.textButton = 'Reset Password'; break;
          case 'tfa': this.textButton = '2FA'; break;
          case 'roles': this.textButton = 'Edit Roles'; break;
        }
      }
    );

    this.listUsers();
  }

  listUsers() {
    this.userService.list().subscribe(
      success => this.runListUsersSuccess(success),
      error => this.runError(error)
    )
  }

  search() {
    const term: string = this.formSearch.controls.term.value;

    if (term)
      this.users = this.users.filter(a => a.name.toLowerCase().includes(term));
    else
      this.listUsers();
  }

  runListUsersSuccess(success: UserGetResponseDto[]): void {
    this.users = success;
    this.ngxSpinnerService.hide();
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
  }
}
