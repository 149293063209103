import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from 'src/app/services/sidebar.service';
import { GlobalService } from '../../services/global.service';
import { UserStorageDto } from '../../dtos/user-storage.dto';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  isCollapsed: boolean;
	isToggled!: boolean;
  public navIsCollapsed: boolean = true;

  user: UserStorageDto;

  constructor(
    public globalService: GlobalService,
    public authService: AuthService,
    private sidebarService: SidebarService,
		
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getAuthenticatedUser()
  }
  toggleSidebar() {
    this.isToggled! = !this.isToggled!;
    this.sidebarService.changeVisibility(this.isToggled!);
}

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/'])
  }
}
