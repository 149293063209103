import { RoleGetResponseDto } from 'src/app/dtos/role-get-response.dto';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserService } from '../../services/user.service';
import { UserRegisterResponseDto } from '../../dtos/user-register-response.dto';
import { RoleService } from '../../services/role.service';
import { UserRegisterRequestDto } from '../../dtos/user-register-request.dto';
import { CustomValidators } from 'ngx-custom-validators';
import * as bcryptjs from 'bcryptjs';

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.scss'],
})
export class UserNewComponent implements OnInit {
  form: FormGroup;
  roles: RoleGetResponseDto[];
  dropdownSettings: IDropdownSettings = {};
  selectedRoles = [];
  request: UserRegisterRequestDto;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private roleService: RoleService,
  ) {
    this.form = this.fb.group(
      {
        name: [
          '',
          [Validators.required, CustomValidators.rangeLength([3, 100])],
        ],
        email: ['', [Validators.required, Validators.email]],
        roles: ['', [Validators.required]],
        password: [
          '',
          [Validators.required, CustomValidators.rangeLength([6, 20])],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: this.checkPasswordConfirmation }
    );
  }

  private checkPasswordConfirmation(group: FormGroup) {
    let password = group.get('password').value;
    let confirmPassword = group.get('confirmPassword').value;
    return password === confirmPassword ? null : { notSame: true };
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 5,
      allowSearchFilter: false,
      enableCheckAll: false,
    };
    this.getRoles();
  }

  getRoles() {
    this.roleService.list().subscribe(
      (success) => this.runSuccessListRoles(success),
      (error) => this.runError(error)
    );
  }

  runSuccessListRoles(success: RoleGetResponseDto[]) {
    this.roles = success;
  }

  onItemSelect(event) {
    const roles: string[] = [];

    roles.push(event._id);
    this.form.patchValue({
      roles: roles,
    });
  }

  async create() {
    this.ngxSpinnerService.show();

    this.form.value.roles = this.selectedRoles.map((r) => r._id);
    this.form.value.confirmPassword = '';

    this.request = Object.assign({}, this.form.value);
    this.request.status = 'active';
    this.request.password = await bcryptjs.hash(this.request.password, 13);

    this.userService.register(this.request).subscribe(
      (success) => this.runSuccessCreateUser(success),
      (error) => this.runError(error)
    );
  }

  runSuccessCreateUser(success: UserRegisterResponseDto) {
    const toastr = this.toastrService.success('Usuário criado com sucesso!', 'Sucesso', {
      progressBar: true,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/pages/user-list');
      });
    }
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
    }
  }
}
