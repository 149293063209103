import { Injectable } from '@angular/core';
import { providers, Wallet, ethers, Contract } from 'ethers';
import { getEffectiveConstraintOfTypeParameter } from 'typescript';
import { environment } from '../../environments/environment';
import { ConfigName } from '../dtos/core-config-name.enum';
import { CoreGetResponseDto } from '../dtos/core.get-response.dtp';
import { AuthService } from './auth.service';
import { CoreService } from './core.service';
import * as TokenJson from '../../assets/contracts/Token.json'

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  wallet: Wallet;
  provider: providers.Provider;
  walletProvider: ethers.Wallet | ethers.providers.Provider;

  public user: User;
  public core: CoreGetResponseDto[];

  ccoinContractInstanceAssign: Contract;
  daiContractInstanceAssign: Contract;
  ccoinContractInstanceNotAssign: Contract;

  
  public adminWalletBalanceETH: number | null;

  constructor(
    private coreService: CoreService,
    public authService:AuthService,
  ) {

    const providerOptions = {
      alchemy: environment.providerOptions.alchemy,
      etherscan: environment.providerOptions.etherscan,
      infura:
        environment.providerOptions.infuraSecret.length > 0
          ? {
            projectId: environment.providerOptions.infuraId,
            projectSecret: environment.providerOptions.infuraSecret,
          }
          : environment.providerOptions.infuraId,
    };

    this.provider = environment.providerOptions.useCustomRpc
      ? new providers.JsonRpcProvider(environment.providerOptions.customRpcURL)
      : ethers.getDefaultProvider(
        ethers.providers.getNetwork(environment.providerOptions.network),
        providerOptions
      );

    // if (!this.core)
    //   this.coreService.list().subscribe(response => {
    //     this.core = response;
    //     this.setListener(this.core.find(a => a.configName == ConfigName.adminWalletAddress).configValue);
    //   });
  }

  setListener(address: string) {
    this.provider.on('block', () => {
      this.updateBalance(address);
    });
  }

  updateBalance(address: string) {
    let lastBalance = ethers.constants.Zero;
    this.provider.getBalance(address).then((balance: ethers.BigNumber) => {
      if (!balance.eq(lastBalance)) {
        lastBalance = balance;
        this.user = new User(
          this.authService.getAuthenticatedUser().email,
          Number(ethers.utils.formatEther(balance)));
      }
    });
  }

  async updateCcoinBalance(){

    const contract = this.ccoinContractInstanceAssign = new Contract(
      localStorage.getItem(ConfigName.colbCoinTokenContractAddress),
      TokenJson.abi,
      this.provider
    )
    const balance = await contract.balanceOf(localStorage.getItem(ConfigName.swapContractAddress))
    return Number(ethers.utils.formatEther(balance)).toFixed(2)
  }

  async updateDaiBalance(){

    const contract = this.daiContractInstanceAssign = new Contract(
      localStorage.getItem(ConfigName.daiTokenContractAddress),
      TokenJson.abi,
      this.provider
    )
    const balance = await contract.balanceOf(localStorage.getItem(ConfigName.swapContractAddress))
    return Number(ethers.utils.formatEther(balance)).toFixed(2)
  }
}

class User {
  constructor(
    public email: string,
    public balanceEth: number,
  ) { }
}

