import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../../services/client.service';

@Component({
  selector: 'app-rebuy-dashboard-card',
  templateUrl: './rebuy-dashboard-card.component.html',
  styleUrls: ['./rebuy-dashboard-card.component.scss']
})
export class RebuyDashboardCardComponent implements OnInit {

  count: number = 0;

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
    this.clientService.listRemarketing().subscribe(
      success => this.count = success.length || 0,
      error => console.error(error)
    )
  }

}
