<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">Rebuy</li>
        <li class="breadcrumb-item" aria-current="page"><a [routerLink]="['/pages/check-rebuy']"> Check rebuy</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Detail</li>
    </ol>
</nav>

<div class="row">
    <div class="col-md-12 col-lg-6">
        <h2 class="mb-3">Rebuy information</h2>

        <div class="card mb-3">
            <div class="card-body">
                <dl class="row">
                    <dt class="col-sm-5">Id</dt>
                    <dd class="col-sm-9">{{rebuy?._id}}</dd>
                    <dt class="col-sm-5">Date</dt>
                    <dd class="col-sm-9">{{rebuy?.createdAt | date}}</dd>
                    <dt class="col-sm-5">Address</dt>
                    <dd class="col-sm-9">{{rebuy?.address}}</dd>
                    <dt class="col-sm-5">Amount Coin</dt>
                    <dd class="col-sm-9">{{rebuy?.amountCoin}}</dd>
                    <!-- <dt class="col-sm-5">Buyback Rate</dt>
                    <dd class="col-sm-9">{{rebuy?.buyBackRate}}</dd> -->
                    <dt class="col-sm-5">Destination Currency</dt>
                    <dd class="col-sm-9">{{rebuy?.destinationCurrency}}</dd>
                    <dt class="col-sm-5">Converted Value</dt>
                    <dd class="col-sm-9">{{rebuy?.convertedValue}}</dd>
                    <dt class="col-sm-5">Status</dt>
                    <dd class="col-sm-9">{{rebuy?.status }}</dd>
                    <dt class="col-sm-5">Bank Name</dt>
                    <dd class="col-sm-9">{{kyc?.bankName }}</dd>
                    <dt class="col-sm-5">Account Number</dt>
                    <dd class="col-sm-9">{{kyc?.accountNumber }}</dd>
                    <dt class="col-sm-5">IBAN</dt>
                    <dd class="col-sm-9">{{kyc?.iban }}</dd>

                </dl>
            </div>
        </div>
    </div>



<div class="col mb-3">
    <div class="col-md-12 col-lg-12">
        <button (click)="approveSwal.fire()" class="btn btn-success btn-lg float-right">
            <em class="fas fa-check"></em> Approve
        </button>
        <button (click)="disapproveSwal.fire()" class="btn btn-danger btn-lg float-right mr-2">
            <em class="fas fa-times"></em> Deny
        </button>
    </div>
</div>

<swal #approveSwal title='Confirmation' text='Do you want to APPROVE this rebuy?' icon='success'
    [confirmButtonText]='"Yes"' [showDenyButton]="true" [focusDeny]="true"
    (confirm)="approve()">
</swal>

<swal #disapproveSwal title='Confirmation' text='Do you want to DENY this rebuy?' icon='error'
    [confirmButtonText]='"Yes"' [showDenyButton]="true" [focusDeny]="true"
    (confirm)="disapprove()">
</swal>