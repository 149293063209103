
import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../../services/client.service';

@Component({
  selector: 'app-kyc-dashboard-card',
  templateUrl: './kyc-dashboard-card.component.html',
  styleUrls: ['./kyc-dashboard-card.component.scss']
})
export class KycDashboardCardComponent implements OnInit {

  count: number = 0;

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
    this.clientService.countInactive().subscribe(
      success => this.count = typeof success==='number'? success:0,
      error => console.error(error)
    )
  }

}