<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" aria-current="page">
      <a [routerLink]="['/pages/dashboard']">Dashboard</a>
    </li>
  </ol>
</nav>

<div class="d-flex justify-content-end align-items-center" style="margin-bottom: 2%;">
  <button class="btn button-color" [routerLink]="['/pages/user-new']">+ Adicionar</button>
</div>
<div class="card card-radius">
  <div class="card-body">
    <div class="form-group">
      <label for="term">Buscar</label>
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Digite o nome completo" [(ngModel)]="term">
      </div>
    </div>
  </div>
</div>

<div class="card card-radius mt-3">
  <div class="card-body">
    <div class="table-responsive card-radius">
      <table class="table table-sm table-hover table-striped ">
        <thead class="button-color">
          <tr>
            <th scope="col" class="text-center ">Id</th>
            <th scope="col" class="text-center">Nome</th>
            <th scope="col" class="text-center">E-mail</th>
            <th scope="col" class="text-center">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of users| filter:term | paginate: { itemsPerPage: itensPerPage, currentPage: currentPage }; let i= index">
            <td class="text-center">{{i+1}}</td>
            <td class="text-center">{{item.name}}</td>
            <td class="text-center">{{item.email}}</td>
            <td class="text-center">
              {{item.status | titlecase}}
            </td>
            <td class="table_icon">
              <a [routerLink]="['/pages/user-edit', item._id]" class="btn btn-outline-secondary">
                {{textButton}}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-right">
      <pagination-controls (pageChange)="currentPage = $event" previousLabel="Anterior" nextLabel="Próximo">
      </pagination-controls>
    </div>
  </div>
</div>