export function renameKeysAndAddValues(inputArray, income, discoverNumber, investments) {
    // Mapeia as chaves atuais para as traduções em português
    const keyTranslations = {
        createdAt: "DataDeCriacao",
        debts: "Dividas",
        fixedCost: "CustosFixos",
        howMuchDoYouNeedToSave: "QuantoVocePrecisaEconomizar",
        income: "Renda",
        installments: "Parcelas",
        insurance: "Seguro",
        investimentsProperty: "InvestimentosEmPropriedades",
        netInvestment: "InvestimentoLiquido",
        objectives: "Objetivos",
        privatePension: "PrevidenciaPrivada",
        updatedAt: "DataDeAtualizacao",
        year: "Ano",
        _id: "id",
    };

    // Cria um novo array com os objetos traduzidos
    const translatedArray = inputArray.map((inputObject, index) => {
        // Cria um novo objeto com as chaves traduzidas
        const translatedObject = {} as any;
        for (const key in inputObject) {
            if (keyTranslations[key]) {
                translatedObject[keyTranslations[key]] = inputObject[key];
            } else {
                translatedObject[key] = inputObject[key];
            }
        }

        // Adiciona novas chaves no primeiro índice para exportar somente na primeira linha

        if(index == 0){
            translatedObject.RendaAtual = income; // Valor fictício de renda
            translatedObject.Investimentos = investments; // Valor fictício de investimentos
            translatedObject.SeuNumero = discoverNumber ? "SIM" : "NÃO"; // Valor fictício de seuNumero
        }
        translatedObject.DataDeCriacao = new Date(translatedObject.DataDeCriacao).toLocaleDateString('pt-BR')
        translatedObject.DataDeAtualizacao = new Date(translatedObject.DataDeAtualizacao).toLocaleDateString('pt-BR')

        return translatedObject;
    });
    return translatedArray
}