export enum ConfigName {
  network = 'network',
  adminWalletAddress = 'adminWalletAddress',
  assetTokenFactoryContractAddress = 'assetTokenFactoryContractAddress',
  colbCoinTokenContractAddress = 'colbCoinTokenContractAddress',
  daiTokenContractAddress = 'daiTokenContractAddress',
  trackerContractAddress = 'trackerContractAddress',
  whitelistContractAddress = 'whitelistContractAddress',
  assetNFTContractAddress = 'assetNFTContractAddress',
  swapContractAddress = 'swapContractAddress',
  trackerUpdaterContractAddress = 'trackerUpdaterContractAddress',
  }