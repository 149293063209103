<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">Deposit</li>
        <li class="breadcrumb-item" aria-current="page"><a [routerLink]="['/pages/check-deposits']"> Check deposits</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Detail</li>
    </ol>
</nav>

<div class="row">
    <div class="col-md-12 col-lg-6">
        <h2 class="mb-3">Deposit information</h2>

        <div class="card mb-3">
            <div class="card-body">
                <dl class="row">
                    <dt class="col-sm-3">Date</dt>
                    <dd class="col-sm-9">{{deposit?.createdAt | date}}</dd>
                    <dt class="col-sm-3">Wallet Address</dt>
                    <dd class="col-sm-9">{{deposit?.walletAddress}}</dd>
                    <dt class="col-sm-3">Currency</dt>
                    <dd class="col-sm-9">{{deposit?.sourceCurrency}}</dd>
                    <dt class="col-sm-3">Your bank reference</dt>
                    <dd class="col-sm-9">{{deposit?.bankReference}}</dd>
                    <dt class="col-sm-3">Value</dt>
                    <dd class="col-sm-9">{{deposit?.amountDeposited | currency }}</dd>
                    <dt class="col-sm-3">Status</dt>
                    <dd class="col-sm-9">{{deposit?.status }}</dd>
                </dl>
            </div>
        </div>
    </div>


    <div class="col-md-12 col-lg-6">
        <h2 class="mb-3">Proof of transaction</h2>

        <div class="card mb-3">
            <div class="card-body">
                <img [src]="img"
                    alt="proffTransaction" class="img-fluid">
            </div>
        </div>
    </div>

</div>
<div class="row mb-3">
    <div class="col-md-12 col-lg-12">
        <button (click)="approveSwal.fire()" class="btn btn-success btn-lg float-right">
            <em class="fas fa-check"></em> Approve
        </button>
        <button (click)="disapproveSwal.fire()" class="btn btn-danger btn-lg float-right mr-2">
            <em class="fas fa-times"></em> Deny
        </button>
    </div>
</div>

<swal #approveSwal title='Confirmation' text='Do you want to APPROVE this deposit?' icon='success'
    [confirmButtonText]='"Yes"' [showDenyButton]="true" [focusDeny]="true"
    (confirm)="approve()">
</swal>

<swal #disapproveSwal title='Confirmation' text='Do you want to DENY this deposit?' icon='error'
    [confirmButtonText]='"Yes"' [showDenyButton]="true" [focusDeny]="true"
    (confirm)="disapprove()">
</swal>