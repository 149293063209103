import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { NotFoundComponent } from './not-found/not-found.component';
import { TopbarComponent } from './topbar/topbar.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    TopbarComponent,
    ForbiddenComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbCollapseModule,
    NgbDropdownModule,
  ],
  exports: [
    NotFoundComponent,
    TopbarComponent,
    ForbiddenComponent
  ]
})
export class NavModule { }
