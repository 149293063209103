import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { StorageDownloadRequestDto } from "../dtos/storage-download-request.dto";
import { StorageDownloadResponseDto } from "../dtos/storage-download-response.dto";
import { BaseService } from "./base.service";

@Injectable()
export class StorageService extends BaseService {
    
    // url: string = environment.Apis.storage;

    // constructor(
    //     private httpClient: HttpClient,
    // ) {
    //     super();
    // }

    // download(dto:StorageDownloadRequestDto):Observable<StorageDownloadResponseDto>{
    //     return this.httpClient
    //     .post(`${this.url}/storage/download`, dto, this.authorizedHeader())
    //     .pipe(
    //         map(this.extractData),
    //         catchError(this.serviceError)
    //     );
    // }
}