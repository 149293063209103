<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Perfil e Permissões</li>
      <li class="breadcrumb-item" aria-current="page">
        <a [routerLink]="['/pages/roles']">Permissões</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Editar Permissões</li>
    </ol>
  </nav>
  <div class="card mb-3 col-sm-12 col-md-6 offset-md-3">
    <div class="card-body">
        <form (ngSubmit)="create()" [formGroup]="form">
        <div class="row">
            <div class="form-group col-lg-6">
              <label>Nome</label>
              <input type="text" class="form-control" formControlName="name">
              <span class="text-danger" *ngIf="form.controls.name.invalid && form.controls.name.dirty">
                <p *ngIf="form.controls.name.errors.required">O nome é requerido!</p>
                <p *ngIf="form.controls.name.errors.rangeLength">O nome tem que ter no minimo 3 caracteres!</p>
              </span>
            </div>
            <div class="form-group col-lg-6">
                <label>Permissões</label>
                <ng-multiselect-dropdown [placeholder]="'Select functions'" [settings]="dropdownSettings" [data]="functions"
                  [(ngModel)]="selectedFunctions" [ngModelOptions]="{standalone: true}" (onSelect)="onItemSelect($event)">
                </ng-multiselect-dropdown>
              </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-12">
              <label>Descrição</label>
              <textarea type="text" class="form-control" formControlName="description"></textarea>
              <span class="text-danger" *ngIf="form.controls.description.invalid && form.controls.description.dirty">
                <p *ngIf="form.controls.description.errors.required">Descrição é requerida!</p>
              </span>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-12">
              <button type="submit" class="btn button-color btn-block" [disabled]="form.invalid">Atualizar</button>
            </div>
        </div>
      </form>
    </div>
  </div>