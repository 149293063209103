<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" aria-current="page">
      <a [routerLink]="['/pages/dashboard']">Dashboard</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Relatório de Sistemas Operacionais</li>
  </ol>
</nav>

<form class="d-flex flex-column" [formGroup]="campaignOne" (ngSubmit)="onSubmit()">
  <div>
    <label class="font-weight-bold">Selecione o período de busca:</label>
    <mat-form-field class="example-form-field" appearance="fill">
      <mat-date-range-input [formGroup]="campaignOne" [rangePicker]="campaignOnePicker">
        <input matStartDate placeholder="Data inicial" formControlName="start">
        <input matEndDate placeholder="Data final" formControlName="end">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
    </mat-form-field>
  </div>

  <button class="btn btn-primary align-self-start" type="submit">Buscar</button>
</form>

<div class="mt-4" *ngIf="android || ios">
  <ul>
    <li>Android: {{android}}</li>
    <li>IOS: {{ios}}</li>
  </ul>
</div>
