import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CoreGetResponseDto } from '../dtos/core.get-response.dtp';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CoreService extends BaseService {

  // url: string = `${environment.Apis.core}/core`;
  // public core: CoreGetResponseDto[];
  // constructor(
  //   private httpClient: HttpClient,
    
  // ) {
  //   super();
  // }

  // loadCoreInfo() {

  // }

  // list(): Observable<CoreGetResponseDto[]> {
  //   return this.httpClient
  //     .get(`${this.url}/config`, this.anonymousHeader())
  //     .pipe(
  //       map(this.extractData),
  //       catchError(this.serviceError)
  //     );
  // }
}
