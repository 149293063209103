<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">Access Control</li>
    <li class="breadcrumb-item" aria-current="page">
      <a [routerLink]="['/pages/user-list']">User</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Edit user</li>
  </ol>
</nav>

<div class="card mb-3 col-sm-12 col-md-4 offset-md-4">
  <div class="card-body">
    <form (ngSubmit)="save()" [formGroup]="form" class="row">

      <input type="hidden" formControlName="_id">

      <div class="form-group col-sm-12">
        <label for="name">Name</label>
        <input type="text" class="form-control" formControlName="name">
        <span class="text-danger" *ngIf="form.controls.name.invalid && form.controls.name.dirty">
          <p *ngIf="form.controls.name.errors.required">Name is required!</p>
          <p *ngIf="form.controls.name.errors.rangeLength">The name must be between 3 and 100 characters!</p>
        </span>
      </div>

      <div class="form-group col-sm-12">
        <label for="email">E-mail</label>
        <input type="email" class="form-control" formControlName="email" readonly>
      </div>

      <div class="form-group col-sm-12">
        <label for="status">Status</label>
        <select class="form-control" formControlName="status">
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>

      <div class="form-group col-sm-12">
        <label for="roles">Roles</label>
        <ng-multiselect-dropdown
          [placeholder]="'Search roles'"
          [settings]="dropdownSettings"
          [data]="roles"
          [(ngModel)]="selectedRoles"
          [ngModelOptions]="{standalone: true}"
        ></ng-multiselect-dropdown>
      </div>
      <button type="submit" class="btn btn-primary btn-block"
        [disabled]="form.invalid">Save</button>
    </form>
  </div>
</div>