import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { TfaRegisterResponseDto } from '../../dtos/tfa-register-response.dto';
import { TfaRegisterRequestDto } from '../../dtos/tfa-register-request.dto';
import { TfaVerifyResponseDto } from '../../dtos/tfa-verify-response.dto';
import { TfaGenerateResponseDto } from '../../dtos/tfa-generate-response.dto';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TfaVerifyRequestDto } from 'src/app/dtos/tfa-verify-request.dto';
import { TfaService } from '../../services/tfa.service';
import { TfaVerifyRegisteredRequestDto } from '../../dtos/tfa-verify-registered-request.dto';
import { AuthService } from '../../services/auth.service';
import { AuthenticateRequestDto } from '../../dtos/authenticate-request.dto';

@Component({
  selector: 'app-user-tfa',
  templateUrl: './user-tfa.component.html',
  styleUrls: ['./user-tfa.component.scss']
})
export class UserTfaComponent implements OnInit {

  tfaGenerateDto: TfaGenerateResponseDto;
  tfaRegistered: boolean = false;

  tfaQrCode: string;
  tfaSecret: string;

  request: AuthenticateRequestDto;

  form: FormGroup;


  constructor(
    private tfaService: TfaService,
    private ngxSpinnerService: NgxSpinnerService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private fb: FormBuilder
  ) {
    this.form = fb.group({
      code: ['', Validators.compose([Validators.required, Validators.pattern('\\d{6}')])],
      password: ['', Validators.compose([Validators.required,])]
    });
  }

  ngOnInit(): void {
    this.tfaService.getTfa().subscribe(
      success => this.successGetTfa(success),
      error => this.runError(error)
    )
  }

  refreshPage(): void {
    this.form.reset(this.form.controls.code);
    this.tfaService.getTfa().subscribe(
      success => this.successGetTfa(success),
      error => this.runError(error)
    )
  }

  successGetTfa(response: any) {
    if (!response.tfaId) {
      this.generateTfa()
    } else {
      this.tfaRegistered = true;
    }
  }

  generateTfa() {
    this.tfaService.generateTfa().subscribe(
      success => this.runSuccessGenerate(success),
      error => this.runError(error)
    );
  }

  runSuccessGenerate(success: TfaGenerateResponseDto) {
    this.tfaGenerateDto = success;
    this.tfaSecret = this.tfaGenerateDto.secret;
    this.tfaQrCode = this.tfaGenerateDto.otpauthUrl;
  }

  register() {
    this.ngxSpinnerService.show();
    this.verify();
  }

  runSuccessRegister(success: TfaRegisterResponseDto) {
    this.tfaRegistered = true;
  }

  verify() {
    this.ngxSpinnerService.show();
    this.tfaService.verifyTfa(new TfaVerifyRequestDto(this.tfaGenerateDto.secret, this.form.controls.code.value)).subscribe(
      success => this.runSuccessVerify(success),
      error => this.runError(error)
    )
  }

  runSuccessVerify(success: TfaVerifyResponseDto) {
    if (!success.valid) {
      const toastr = this.toastrService.error('Código inválido!', 'Error', {
        progressBar: true
      });

      if (toastr)
        toastr.onHidden.subscribe(() => {
          this.ngxSpinnerService.hide();
        });
    } else {

      this.authService.validateUser(this.form.value.password).subscribe(
        success => {
          if (success.valid == true) {
            this.tfaService.registerTfa(new TfaRegisterRequestDto(this.tfaGenerateDto.secret, this.tfaGenerateDto.otpauthUrl)).subscribe(
              success => this.runSuccessRegistry(success),
              error => this.runError(error)
            )
          } else {
            this.runInvalidPasswordMessage()
            this.refreshPage();
          }
        },
        error => this.runError(error)
      )
    }
  }


  runSuccessRegistry(success: TfaRegisterResponseDto) {
    const toastr = this.toastrService.success('2FA ativado com sucesso!', 'Success', {
      progressBar: true
    });
    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
    }
    this.tfaRegistered = false;
    this.tfaQrCode = null;
    this.refreshPage();
  }

  verifyCodeToDelete() {
    this.ngxSpinnerService.show();
    this.tfaService.verifyRegisteredTfa(new TfaVerifyRegisteredRequestDto(this.form.controls.code.value)).subscribe(
      success => { },
      error => this.runError(error)
    )
  }

  disableTfa() {
    this.ngxSpinnerService.show();
    this.tfaService.verifyRegisteredTfa(new TfaVerifyRegisteredRequestDto(this.form.controls.code.value)).subscribe(
      success => {
        if (success.valid == true) {
          
          this.authService.validateUser(this.form.value.password).subscribe(
            success => {
              if (success.valid == true) {
                this.tfaService.deleteTfa().subscribe(
                  success => this.runSuccessDelete(success),
                  error => this.runError(error)
                )
              } else {
                this.runInvalidPasswordMessage()
                this.refreshPage();
              }
            },
            error => this.runError(error)
          )
        } else {
          const toastr = this.toastrService.error('Código invalido!', 'Error', {
            progressBar: true
          });
          if (toastr)
            toastr.onHidden.subscribe(() => {
              this.ngxSpinnerService.hide();
            });
        };
      },
      error => this.runError(error)
    )

  }

  runSuccessDelete(success: any) {
    if (success)
      this.tfaRegistered = false;
    const toastr = this.toastrService.success('2FA desabilitado com sucesso!', 'Success', {
      progressBar: true
    });
    this.tfaRegistered = false
    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
    }
    this.refreshPage();
  }

  runInvalidPasswordMessage(): void {
    const toastr = this.toastrService.error('Senha inválida', 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.errors[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
  }
}


