<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a [routerLink]="['/pages/dashboard']">Dashboard</a>
        </li>
    </ol>
</nav>

<div class="d-flex justify-content-end align-items-center" style="margin-bottom: 2%;">
    <button class="btn button-color" [routerLink]="['/pages/wallet-new']">+ Carteira</button>
</div>

<div class="card card-radius">
    <div class="card-body">
        <div class="form-group">
            <label for="term">Buscar</label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Digite aqui para buscar" [(ngModel)]="term">
            </div>
        </div>
    </div>
</div>


<div class="card card-radius mt-3">
    <div class="card-body">
        <div class="table-responsive card-radius">
            <table class="table table-sm table-hover table-striped">
                <thead class="button-color">
                    <tr>
                        <th scope="col" class="text-center">#</th>
                        <th scope="col" class="text-center">Taxa</th>
                        <th scope="col" class="text-center">Nome</th>
                        <th scope="col"></th>
                        <th scope="col"></th>

                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of list | filter:term | paginate: { itemsPerPage: itensPerPage, currentPage: currentPage }; let i= index">
                        <td class="text-center">{{i+1}}</td>
                        <td class="text-center">{{item.taxa}}</td>
                        <td class="text-center">{{item.nome}}</td>
                        <td class="table_icon">
                            <button [routerLink]="['/pages/wallet-edit', item._id]"
                                class="btn col-lg-12 btn-outline-secondary">
                                Editar
                            </button>
                        </td>
                        <td class="table_icon">
                            <button (click)="deleteSwal.fire(); selectIdDelete(item._id)"
                                class="btn col-lg-12 btn-danger">
                                Deletar
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls (pageChange)="currentPage = $event" previousLabel="Anterior" nextLabel="Próximo">
            </pagination-controls>
        </div>
    </div>
</div>

<swal #deleteSwal title='Confirmação' text='Você deseja deletar esta carteira?' icon='error' [confirmButtonText]='"Yes"'
    [showDenyButton]="true" [focusDeny]="true" (confirm)="delete()">
</swal>

<!-- <div class="modal fade" id="modalWallet" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalrole">+ Adicionar nova carteira</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-wallet-new></app-wallet-new>
            </div>

        </div>
    </div>
</div> -->