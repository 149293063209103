import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { BaseService } from "./base.service";

@Injectable()
export class WhitelistService extends BaseService {

    // url: string = environment.Apis.core;

    // constructor(
    //     private httpClient: HttpClient
    // ) {
    //     super();
    // }

    // include(address: string): Observable<any> {

    //     const body = {
    //         address
    //     };

    //     return this.httpClient
    //         .post(`${this.url}/whitelist/include`, body, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }

    // remove(address: string): Observable<any> {

    //     return this.httpClient
    //         .delete(`${this.url}/whitelist/remove/${address}`, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }

    // isIn(address: string): Observable<any> {

    //     return this.httpClient
    //         .delete(`${this.url}/whitelist/isIn/${address}`, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }
}