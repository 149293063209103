import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KycGetResponseDto } from '../dtos/kyc-get-response.dto';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class KycService extends BaseService {
  // url: string = environment.Apis.kyc;

  // constructor(private http: HttpClient) {
  //   super();
  // }

  // list(): Observable<KycGetResponseDto[]> {
  //   return this.http
  //     .get(`${this.url}/kyc`, this.authorizedHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }
  // listPending(): Observable<KycGetResponseDto[]> {
  //   return this.http
  //     .get(`${this.url}/kyc/pending`, this.authorizedHeader())
  //     .pipe(
  //       map(this.extractData),
  //       catchError(this.serviceError)
  //     );
  // }

  // listCount(): Observable<any> {
  //   return this.http.get(`${this.url}/kyc/pending/count`, this.authorizedHeader())
  //     .pipe(
  //       map(this.extractData),
  //       catchError(this.serviceError)
  //     )
  // }
  // listVerified(): Observable<KycGetResponseDto[]> {
  //   return this.http
  //     .get(`${this.url}/kyc/verified`, this.authorizedHeader())
  //     .pipe(
  //       map(this.extractData),
  //       catchError(this.serviceError)
  //     );
  // }
  // getById(id: string): Observable<KycGetResponseDto> {
  //   return this.http
  //     .get(`${this.url}/kyc/${id}`, this.authorizedHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // updateStatus(id, status): Observable<any> {
  //   return this.http
  //     .put(`${this.url}/kyc/status/${id}`, status, this.authorizedHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }
}