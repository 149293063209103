import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ethers } from 'ethers';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DepositDennyRequestDto } from 'src/app/dtos/deposit-denny-request.dto';
import { DepositGetResponseDto } from 'src/app/dtos/deposit-get-response.dto';
import { DepositStatusEnum } from '../../dtos/deposit-status.enum';
import { DepositUpdateStatusRequestDto } from '../../dtos/deposit-update-status-request.dto';
import { DepositUpdateStatusResponseDto } from '../../dtos/deposit-update-status-response.dto';
import { MessageRegisterRequestDto } from '../../dtos/message-register-request.dto';
import { StorageDownloadRequestDto } from '../../dtos/storage-download-request.dto';
import { CcoinService } from '../../services/ccoin.service';
import { DepositService } from '../../services/deposits.service';
import { GlobalService } from '../../services/global.service';
import { MessageService } from '../../services/message.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-check-deposits-detail',
  templateUrl: './check-deposits-detail.component.html',
  styleUrls: ['./check-deposits-detail.component.scss'],
})
export class CheckDepositsDetailComponent implements OnInit {
  deposit: DepositGetResponseDto;
  img;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private depositService: DepositService,
    private ccoinService: CcoinService,
    private storageService: StorageService,
    private messageService: MessageService,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {
  }
  //   this.ngxSpinnerService.show();

  //   this.deposit = this.route.snapshot.data['deposit'];

  //   this.storageService
  //     .download(new StorageDownloadRequestDto(this.deposit._id))
  //     .subscribe((success) => {
  //       this.img = this.sanitizer.bypassSecurityTrustResourceUrl(
  //         `${success.file}`
  //       );
  //     });

  //   this.ngxSpinnerService.hide();
  // }

  async approve() {
  }
  //   try {
  //     this.ngxSpinnerService.show();

  //     this.ccoinService
  //       .mint(this.deposit.walletAddress, this.deposit.amountDeposited)
  //       .subscribe(
  //         (success) => this.runMintSuccess(success),
  //         (error) => this.runError('Error executing mint')
  //       );
  //   } catch (error) {
  //     const toastr = this.toastrService.error('Error to mint token', 'Error', {
  //       progressBar: true,
  //     });

  //     if (toastr)
  //       toastr.onHidden.subscribe(() => {
  //         this.ngxSpinnerService.hide();
  //       });
  //   }
  // }

  async runMintSuccess(success: any) {
    // this.depositService
    //   .updateStatus(
    //     this.deposit._id,
    //     new DepositUpdateStatusRequestDto(
    //       DepositStatusEnum.sent,
    //       success.transaction
    //     )
    //   )
    //   .subscribe(
    //     (success) => this.runSuccessUpdateStatusSent(success),
    //     (error) => this.runError('Error updating deposit status')
    //   );

    // const tx = await this.globalService.provider.getTransaction(
    //   success.transaction
    // );
    // tx.wait()
    //   .then((response) => {
    //     this.depositService
    //       .updateStatus(
    //         this.deposit._id,
    //         new DepositUpdateStatusRequestDto(
    //           DepositStatusEnum.approve,
    //           success.transaction
    //         )
    //       )
    //       .subscribe(
    //         (success) => this.runApprovedSuccess(success),
    //         (error) => this.runError('Error updating deposit status')
    //       );
    //   })
    //   .catch((error) => {});

    // const toastr = this.toastrService.success(
    //   `Deposit transaction ${success.transaction} created`,
    //   'Success',
    //   {
    //     progressBar: true,
    //   }
    // );

    // if (toastr)
    //   toastr.onHidden.subscribe(() => {
    //     this.router.navigateByUrl('/pages/check-deposits');
    //     this.ngxSpinnerService.hide();
    //   });
  }

  async runSuccessUpdateStatusSent(success: any) {}

  runApprovedSuccess(success: DepositUpdateStatusResponseDto) {
    // this.messageService
    //   .register(
    //     this.deposit.walletAddress,
    //     new MessageRegisterRequestDto(
    //       'Confirmed deposit',
    //       `Deposit of ${this.deposit.amountDeposited} ${this.deposit.sourceCurrency} was made to wallet with address ${this.deposit.walletAddress}`
    //     )
    //   )
    //   .subscribe(
    //     (success) => this.runRegisterMessageSuccess(success),
    //     (error) => this.runError('Error sending message')
    //   );
  }

  runRegisterMessageSuccess(success: any) {
    const toastr = this.toastrService.success('Deposit approved', 'Success', {
      progressBar: true,
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.router.navigateByUrl('/pages/check-deposits');
        this.ngxSpinnerService.hide();
      });
  }

  disapprove() {
    // this.ngxSpinnerService.show();

    // this.depositService
    //   .dennyStatus(
    //     this.deposit._id,
    //     new DepositDennyRequestDto(
    //       DepositStatusEnum.pending,
    //       )
    //       )
    //   .subscribe(
    //     (success) => this.runDisapprovedSuccess(success),
    //     (error) => this.runError('Error updating deposit status')
    //   );
  }

  runDisapprovedSuccess(success: any): void {
    // this.messageService
    //   .register(
    //     this.deposit.walletAddress,
    //     new MessageRegisterRequestDto(
    //       'Deposit Denied',
    //       `Deposit of ${this.deposit.amountDeposited} USD was not approved.`
    //     )
    //   )
    //   .subscribe(
    //     (success) => this.runRegisterMessageDeniedSuccess(success),
    //     (error) => this.runError('Error sending message')
    //   );
  }

  runRegisterMessageDeniedSuccess(success: any) {
    const toastr = this.toastrService.success('Deposit disapprove', 'Success', {
      progressBar: true,
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.router.navigateByUrl('/pages/check-deposits');
        this.ngxSpinnerService.hide();
      });
  }
  runError(message: string) {
    const toastr = this.toastrService.error(message, 'Error', {
      progressBar: true,
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
  }
}
