<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">KYC</li>
        <li class="breadcrumb-item" aria-current="page"><a [routerLink]="['/pages/check-kyc']"> Check KYC
                requests</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Detail</li>
    </ol>
</nav>

<div class="row">
    <div class="col-md-12 col-lg-6">
        <h2 class="mb-3">KYC information</h2>

        <div class="card mb-3">
            <div class="card-body">
                <dl class="row">
                    <dt class="col-sm-3">Request date</dt>
                    <dd class="col-sm-9">{{request?.createdAt}}</dd>
                    <dt class="col-sm-3">Update date</dt>
                    <dd class="col-sm-9">{{request?.updatedAt}}</dd>
                    <dt class="col-sm-3">Status</dt>
                    <dd class="col-sm-9">{{request?.status }}</dd>
                    <dt class="col-sm-3">Name</dt>
                    <dd class="col-sm-9">{{request?.lastName }}, {{request?.firstName}}</dd>
                    <dt class="col-sm-3">Wallet</dt>
                    <dd class="col-sm-9">{{request?.walletAddress }}</dd>
                    <!-- <dt class="col-sm-3">Birth date</dt>
                    <dd class="col-sm-9">{{request?.birth }}</dd> -->
                    <dt class="col-sm-3">Nacionality</dt>
                    <dd class="col-sm-9">{{request?.nacionality }}</dd>
                    <dt class="col-sm-3">US person</dt>
                    <dd class="col-sm-9">{{request?.usPerson }}</dd>
                    <dt class="col-sm-3">E-mail</dt>
                    <dd class="col-sm-9">{{request?.email }}</dd>
                    <dt class="col-sm-3" *ngIf="request?.phone != '+null null'">Telephone</dt>
                    <dd class="col-sm-9" *ngIf="request?.phone != '+null null'">{{request?.phone }}</dd>
                    <dt class="col-sm-3">Address</dt>
                    <dd class="col-sm-9">{{request?.address }}</dd>
                    <dt class="col-sm-3" *ngIf="request?.addressLine2">&nbsp;</dt>
                    <dd class="col-sm-9" *ngIf="request?.addressLine2">{{request?.addressLine2 }}</dd>
                    <dt class="col-sm-3" *ngIf="request?.addressLine3">&nbsp;</dt>
                    <dd class="col-sm-9" *ngIf="request?.addressLine3">{{request?.addressLine3 }}</dd>
                    <dt class="col-sm-3" *ngIf="request?.addressLine4">&nbsp;</dt>
                    <dd class="col-sm-9" *ngIf="request?.addressLine4">{{request?.addressLine4 }}</dd>
                    <dt class="col-sm-3">Postal code</dt>
                    <dd class="col-sm-9">{{request?.zipCode }}</dd>
                    <dt class="col-sm-3">Country</dt>
                    <dd class="col-sm-9">{{request?.country }}</dd>
                    <dt class="col-sm-3">Bank name</dt>
                    <dd class="col-sm-9">{{request?.bankName }}</dd>
                    <dt class="col-sm-3">Account number</dt>
                    <dd class="col-sm-9">{{request?.accountNumber }}</dd>
                    <dt class="col-sm-3">IBAN</dt>
                    <dd class="col-sm-9">{{request?.iban }}</dd>
                </dl>
            </div>
        </div>
    </div>

    <div class="col-md-12 col-lg-6">

        <div class="row">
            <div class="col-md-6 col-lg-6">
                <h2 class="mb-3">Proof of address</h2>

                <div class="card mb-3">
                    <div class="card-body">
                        <img [src]="proofAddress"
                            alt="profAddress" class="img-fluid">
                    </div>
                </div>
            </div>


            <div class="col-md-6 col-lg-6">
                <h2 class="mb-3">Passport</h2>

                <div class="card mb-3">
                    <div class="card-body">
                        <img [src]="passport" alt="passport" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row mb-3">
    <div class="col-md-12 col-lg-12">
        <button (click)="approveSwal.fire()" class="btn btn-success btn-lg float-right">
            <em class="fas fa-check"></em> Approve
        </button>
        <button (click)="disapproveSwal.fire()" class="btn btn-danger btn-lg float-right mr-2">
            <em class="fas fa-times"></em> Deny
        </button>
    </div>
</div>

<swal #approveSwal title='Confirmation' text='Do you want to APPROVE this KYC?' icon='success'
    [confirmButtonText]='"Yes"' [showDenyButton]="true" [focusDeny]="true"
    (confirm)="approve()">
</swal>

<swal #disapproveSwal title='Confirmation' text='Do you want to DENY this KYC?' icon='error'
    [confirmButtonText]='"Yes"' [showDenyButton]="true" [focusDeny]="true"
    (confirm)="disapprove()">
</swal>