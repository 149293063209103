<div class="row mt-5">
    <!-- <div class="col-md-4">
        <app-deposit-dashboard-card></app-deposit-dashboard-card>
    </div> -->

    <div class="col-md-4">
        <app-pgc-dashboard-card></app-pgc-dashboard-card>
    </div>

    <div class="col-md-4">
        <app-rebuy-dashboard-card></app-rebuy-dashboard-card>
    </div>

    <div class="col-md-4">
        <app-rvc-dashboard-card></app-rvc-dashboard-card>
    </div>
</div>

<div class="row mt-5">
    <div class="col-md-4">
        <app-logs-dashboard-card></app-logs-dashboard-card>
    </div>

    <div class="col-md-4">
        <advisor-dashboard-card></advisor-dashboard-card>
    </div>
</div>