import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { RoleGetResponseDto } from '../dtos/role-get-response.dto';
import { RoleService } from '../services/role.service';

@Injectable()
export class RolesResolve implements Resolve<RoleGetResponseDto> {

    constructor(
        private roleService: RoleService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.roleService.getById(route.params['id']);
    }
}