import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AdvisorDto, UserStatusEnum } from "../dtos/advisor.dto";
import { BuysGoldInterface } from "../dtos/buys-gold.dto";
import { PersonalInfoAdvisorDto } from "../dtos/personal-info-advisor.dto";
import { CombinedDataPurchasesInterface } from "../dtos/combined-data-purchases.dto";
import { PhotoProfileAdvisorDto } from "../dtos/photo-profile-advisor.dto";
import { ExportData } from "../dtos/export-data.dto";

@Injectable()
export class AdvisorService extends BaseService {
    url: string = environment.Apis.advisor;

    constructor(private httpClient: HttpClient) {
        super();
    }

    list(): Observable<{ data: AdvisorDto[] }> {
        return this.httpClient
            .get<{ data: AdvisorDto[] }>(`${this.url}/user`)
            .pipe(catchError(this.serviceError));
    }

    listBuysGoldBySpecialist(email: string): Observable<{ data: CombinedDataPurchasesInterface[] }> {
        return this.httpClient
            .get<{ data: CombinedDataPurchasesInterface[] }>(`${this.url}/app/listBySpecialist/${email}`)
            .pipe(catchError(this.serviceError));
    }

    getExportData(): Observable<{ data: ExportData[] }> {
        return this.httpClient
            .get<{ data: ExportData[] }>(`${this.url}/app/exportData`)
            .pipe(catchError(this.serviceError));
    }

    listRevenueCatPurchasesBySpecialistForConcat(email: string): Observable<{ data: CombinedDataPurchasesInterface[] }> {
        return this.httpClient
            .get<{ data: CombinedDataPurchasesInterface[] }>(`${this.url}/app/listBySpecialist-revenue/${email}`)
            .pipe(catchError(this.serviceError));
    }

    getPersonalInfoByEmail(email: string): Observable<{ data: PersonalInfoAdvisorDto }> {
        return this.httpClient
            .get<{ data: PersonalInfoAdvisorDto }>(`${this.url}/user/personal-info-by-email/${email}`)
            .pipe(catchError(this.serviceError));
    }

    getImageDataProfile(email: string): Observable<{ data: PhotoProfileAdvisorDto }> {
        return this.httpClient
            .get<{ data: PhotoProfileAdvisorDto }>(`${this.url}/photo-profile/email-admin/${email}`)
            .pipe(catchError(this.serviceError));
    }

    getCountPremiumClients(): Observable<{ data: number[] }> {
        return this.httpClient
            .get<{ data: number[] }>(`${this.url}/user/getCountPremiumClients`)
            .pipe(catchError(this.serviceError));
    }

    updateCheckCommissionPayment(req: { id: string, checkCommissionPayment: boolean }): Observable<{ data: BuysGoldInterface }> {
        return this.httpClient
            .put<{ data: BuysGoldInterface }>(`${this.url}/app/updateCheckCommissionPayment`, req)
            .pipe(catchError(this.serviceError));
    }

    updateCommunity(req: { id: string, community: boolean }): Observable<{ data: AdvisorDto }> {
        return this.httpClient
            .post<{ data: AdvisorDto }>(`${this.url}/user/community`, req)
            .pipe(catchError(this.serviceError));
    }

    updateCanBuyPremium(req: { id: string, canBuyPremium: boolean }): Observable<{ data: AdvisorDto }> {
        return this.httpClient
            .post<{ data: AdvisorDto }>(`${this.url}/user/canBuyPremium`, req)
            .pipe(catchError(this.serviceError));
    }

    changeStatus(req: { email: string, status: UserStatusEnum }): Observable<{ data: AdvisorDto }> {
        return this.httpClient
            .post<{ data: AdvisorDto }>(`${this.url}/user/change-status`, req)
            .pipe(catchError(this.serviceError));
    }

    sendRefusedEmail(req: { email: string, message: string,  disapproved: boolean }): Observable<{ data: AdvisorDto }> {
        return this.httpClient
            .post<{ data: AdvisorDto }>(`${this.url}/user/sendRefusedEmail`, req)
            .pipe(catchError(this.serviceError));
    }

    deleteAllOfUser(email: string): Observable<{ data: AdvisorDto }> {
        return this.httpClient
            .delete(`${this.url}/deleteAll/email/${email}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }


}