<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a [routerLink]="['/pages/dashboard']">Dashboard</a>
            <a class="breadcrumb-link" [routerLink]="['/pages/progress-customers']">Ciclo de vida</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Clientes exportados</li>
    </ol>
</nav>


<div class="card card-radius mb-3" *ngIf="users.length > 1">
    <div class="card-body d-flex justify-content-center align-items-center padding-10">
        <div class="d-block">
            <p class="text-center m-0 fs-1">Navegar entre usuários</p>
            <div class="d-flex align-items-center">
                <span class="text-secondary">Analisando usuário {{ currentPage + 1 }} de {{ users.length }}</span>
                <mat-paginator [length]="users.length" [pageSize]="1" [pageSizeOptions]="[1,2,3,4]"
                    aria-label="Select page" (page)="onPageChange($event)">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>

<div class="buttons-export">
    <a (click)="exportFluxoFinanceiro()" class="btn btn-outline-success w-100">Exportar planilha com todos os
        usuários</a>
</div>

<div class="card card-radius mt-3">
    <div class="card-body padding-0">
        <div class="cash-flow d-flex justify-content-center text-center">
            <div class="d-block">
                <h1>FLUXO FINANCEIRO!</h1>
                <h2>Entradas e saídas de todo <span>Meu projeto de vida</span>
                </h2>
            </div>
        </div>
        <ng-container *ngFor="let user of pageSlice ; let i= index">
            <div class="user-card d-flex align-items-center text-center mt-5">
                <div class="user-email w-50 d-flex justify-content-center">
                    <div class="d-block">
                        <span class="user-title">Cliente analisado:</span>
                        <p>{{ user.user }}</p>
                    </div>
                </div>
                <div class="user-date d-flex w-50 justify-content-center">
                    <div class="d-block">
                        <p class="mb-0">
                            <span class="user-title">Criado em:</span>
                            {{ user.report[0].createdAt.substr(0,10) }}
                        </p>
                        <p>
                            <span class="user-title">Atualizado em:</span>
                            {{ user.report[0].updatedAt.substr(0,10) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="user-card d-flex align-items-center text-center mt-3">
                <div class="user-email w-50 d-flex justify-content-center">
                    <div class="d-block">
                        <p class="mb-0">
                            <span class="user-title">Idade do cliente: {{ user.age }}</span>
                        </p>
                        <p>
                            <span class="user-title">Juros do projeto: {{ user.yeild * 100 }}%</span>
                        </p>
                    </div>
                </div>
                <div class="user-date d-flex w-50 justify-content-center">
                    <div class="d-block">
                        <p class="mb-0">
                            <span class="user-title">Preço da independência: R${{user.numberIF}}</span>
                        </p>
                        <p>
                            <span class="user-title">Renda alcançada: R${{user.incomeIF}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="user-card d-flex flex-column justify-content-center align-items-center text-center mb-5 mr-5 ml-5 mt-3">

                <table class="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Objetivos</th>
                            <th scope="col">Periodicidade</th>
                            <th scope="col">Valor fixo</th>
                            <th scope="col">Até a Independência</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let objective of user.objectives.arrAll">
                            <td>{{objective.name}}</td>
                            <td>
                                {{objective.changeVehicleTime != 0
                                ? objective.changeVehicleTime + "em" + objective.changeVehicleTime + "anos"
                                : objective.periodicity === "recorrente"
                                ? 'Anual' : objective.year}}
                            </td>
                            <td>
                                {{objective.habitationFinanceValue != 0
                                ? objective.value + objective.habitationFinanceValue
                                : objective.value}}
                            </td>
                            <td>{{objective.untilIndependence}}</td>
                        </tr>

                    </tbody>
                </table>


            </div>

            <div class="table-responsive">
                <table class="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Ano</th>
                            <th scope="col">Renda</th>
                            <th scope="col">Custo fixo</th>
                            <th scope="col">Dívidas</th>
                            <th scope="col">Seguro</th>
                            <th scope="col">Objetivos</th>
                            <th scope="col">Poupar</th>
                            <th scope="col">Previdência privada</th>
                            <th scope="col">Investimento líquido</th>
                            <th scope="col">Patrimônio imobilizado</th>
                        </tr>
                    </thead>
                    <br>
                    <tbody>
                        <tr *ngFor="let report of user.report | filter:term ; let i = index">
                            <td>{{ report.year }}</td>
                            <td>R$ &nbsp;{{ (report.income).toFixed(2) }}</td>
                            <td class="text-danger">R$ &nbsp;{{ report.fixedCost.toFixed(2) }}</td>
                            <td class="text-danger">R$ &nbsp;{{ report.debts.toFixed(2) }}</td>
                            <td class="text-danger">R$ &nbsp;{{ report.insurance.toFixed(2) }}</td>
                            <td class="text-danger">R$ &nbsp;{{ report.objectives.toFixed(2) }}</td>
                            <td [ngClass]="report.howMuchDoYouNeedToSave < 0 ? 'text-danger' : 'text-dark' ">R$ &nbsp;{{
                                report.howMuchDoYouNeedToSave.toFixed(2) }}</td>
                            <td>R$ &nbsp;{{ report.privatePension.toFixed(2) }}</td>
                            <td>R$ &nbsp;{{ report.netInvestment.toFixed(2) }}</td>
                            <td>R$ &nbsp;{{ report.investimentsProperty.toFixed(2) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</div>