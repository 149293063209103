import { TfaGetResponseDto } from '../dtos/tfa-get-response.dto';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { TfaService } from '../services/tfa.service';

@Injectable()
export class TfaResolve implements Resolve<TfaGetResponseDto> {

    constructor(
        private tfaService: TfaService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.tfaService.getTfa();
    }
}