
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KycGetResponseDto } from 'src/app/dtos/kyc-get-response.dto';
import { KycService } from 'src/app/services/kyc.service';
import { WhitelistService } from '../../services/whitelist.service';
import { GlobalService } from '../../services/global.service';
import { StorageService } from 'src/app/services/storage.service';
import { StorageDownloadRequestDto } from 'src/app/dtos/storage-download-request.dto';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageRegisterRequestDto } from '../../dtos/message-register-request.dto';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-check-kyc-details',
  templateUrl: './check-kyc-details.component.html',
  styleUrls: ['./check-kyc-details.component.scss']
})
export class CheckKycDetailsComponent implements OnInit {

  request;
  address;
  kyc = this.route.snapshot.data['kyc'];
  passport;
  proofAddress;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private kycService: KycService,
    private whitelistService: WhitelistService,
    private globalService: GlobalService,
    private storageService: StorageService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    // this.ngxSpinnerService.show();
    // this.getDetail(this.kyc._id);
    // this.storageService.download(new StorageDownloadRequestDto(`${this.kyc._id}_1`)).subscribe(
    //   success => {
    //     this.passport = this.sanitizer.bypassSecurityTrustResourceUrl(success.file);

    //   }
    // );
    // this.storageService.download(new StorageDownloadRequestDto(`${this.kyc._id}_2`)).subscribe(
    //   success => {
    //     this.proofAddress = this.sanitizer.bypassSecurityTrustResourceUrl(success.file);

    //   }
    // );
    // this.ngxSpinnerService.hide();
  }

  approve() {
    // this.ngxSpinnerService.show();

    // this.whitelistService.include(this.kyc.walletAddress).subscribe(
    //   success => this.runIncludeSuccess(success),
    //   error => this.runError(error)
    // );
  }

  async runIncludeSuccess(response: any) {
    // // this.kycService.updateStatus(this.kyc._id, { 'status': 'verified', 'transactionHash': response.transaction }).subscribe(
    // //   success => { 
    // //     this.toastrService.success(`Include successfully`, 'Success', {
    // //       progressBar: true,
    // //     });

    // //    },
    // //   error => this.runError(error)
    // )

    // const tx = await this.globalService.provider.getTransaction(response.transaction);
    // tx.wait()
    //   .then((tx) => {

    //     this.messageService.register(this.kyc.walletAddress,
    //       new MessageRegisterRequestDto('KYC process', 'Your KYC have been approved!')).subscribe(
    //         success => this.runRegisterMessageApproveSuccess(response),
    //         error => this.runError('Error sending message')
    //       );
    //     this.toastrService.success(`KYC transaction ${response.transaction} confirmed`, 'Success', {
    //       progressBar: true,
    //     });

    //   }).catch((error) => {
    //     error
    //   });
  }

  runRegisterMessageApproveSuccess(response: any) {
    // const toastr = this.toastrService.success(`KYC transaction ${response.transaction} created`, 'Success', {
    //   progressBar: true,
    // });
    // if (toastr)
    //   toastr.onHidden.subscribe(() => {
    //     this.router.navigateByUrl('/pages/check-kyc');
    //     this.ngxSpinnerService.hide();
    //   });
  }


  getDetail(id) {
    // this.kycService.getById(id).subscribe(
    //   success => this.runListSuccess(success),
    //   error => this.runError(error)
    // )
  }


  disapprove() {
    // this.ngxSpinnerService.show();
    // this.kycService.updateStatus(this.kyc._id, { 'status': 'restricted' }).subscribe(
    //   success => this.runDisapproveSuccess(success),
    //   error => this.runError(error)
    // )
  }

  runDisapproveSuccess(response: string) {
    // this.messageService.register(this.kyc.walletAddress,
    //   new MessageRegisterRequestDto('KYC process', 'KYC have not been approved. Please, try again')).subscribe(
    //     success => this.runRegisterMessageDisapproveSuccess(success),
    //     error => this.runError('Error sending message')
    //   );
  }

  runRegisterMessageDisapproveSuccess(success: any) {
    const toastr = this.toastrService.success('KYC denied', 'Success', {
      progressBar: true
    });
    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.router.navigateByUrl('/pages/check-kyc');
        this.ngxSpinnerService.hide();
      })
    };
  }

  runListSuccess(success: KycGetResponseDto): void {
    this.address = success.address[0].split(';');
    this.request = {
      createdAt: success.createdAt.slice(0, 10),
      updatedAt: success.updatedAt.slice(0, 10),
      status: success.status,
      firstName: success.firstName,
      lastName: success.lastName,
      birth: success.birth,
      nacionality: success.nationality,
      usPerson: false,
      email: success.email,
      phone: success.phone,
      address: this.address,
      zipCode: success.zipCode,
      country: success.country,
      bankName: success.bankName,
      accountNumber: success.accountNumber,
      iban: success.iban,
      walletAddress: success.walletAddress,
    };
    this.ngxSpinnerService.hide();
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
  }
}