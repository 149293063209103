<div class="bg-light shadow" id="sidebar-wrapper">
    <div class="sidebar-heading text-center">
        <a [routerLink]="['/pages/dashboard']"> <img src="./../../../assets/logowalkr.png" width="200"
                alt="Logo Walkr" /></a>
    </div>

    <div class="text-center text-white mt-5">
        <img src="./../../../assets/logo_azul.svg" height="80" />
        <p><b>{{ currentUser.name }}</b></p>
        <p class="p-marg"><small>{{ currentUser.email }}</small> </p>
    </div>
    <div class="sidebar-title colortext shadow p-2"><b>&nbsp;SuperAdmin</b></div>
    <ul class="nav flex-column">
        <li class="nav-item ">
            <a [routerLink]="['/pages/dashboard']" class="nav-link text-white sidebar-menu"> <i
                    class="fa fa-columns"></i> Dashboard</a>
        </li>

    </ul>
    <ul class="nav flex-column">
        <li class="nav-item ">
            <a [routerLink]="['/pages/operating-systems']" class="nav-link text-white sidebar-menu"> <i
                    class="fas fa-mobile"></i> Relatório de Sistemas Operacionais</a>
        </li>
    </ul>
    <ul class="nav flex-column">
        <li class="nav-item ">
            <a [routerLink]="['/pages/notifications']" class="nav-link text-white sidebar-menu"> <i
                    class="fas fa-bell"></i> Notificações</a>
        </li>
    </ul>
    <ul class="nav flex-column">
        <li class="nav-item ">
            <a [routerLink]="['/pages/user-list']" class="nav-link  text-white sidebar-menu"> <i
                    class="fa fa-users"></i> Gestão de usuários</a>
        </li>
    </ul>
    <ul class="nav flex-column">
        <li class="nav-item ">
            <a [routerLink]="['/pages/roles']" class="nav-link text-white sidebar-menu"> <i class="fa fa-handshake"></i>
                Perfil e permissões</a>
        </li>
    </ul>

    <ul class="nav flex-column">
        <li class="nav-item ">
            <a [routerLink]="['/pages/user-tfa']" class="nav-link  text-white sidebar-menu"> <i class="fa fa-lock"></i>
                2FA</a>
        </li>
    </ul>

    <ul class="nav flex-column">
        <li class="nav-item ">
            <a [routerLink]="['/pages/wallet']" class="nav-link  text-white sidebar-menu"> <i class="fa fa-wallet"></i>
                Carteira</a>
        </li>

    </ul>


</div>