<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">Access Control</li>
    <li class="breadcrumb-item" aria-current="page">
      <a [routerLink]="['/pages/wallet']">Perfil</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Adicionar perfil</li>
  </ol>
</nav>

<div class="card mb-3 col-sm-12 col-md-6 offset-md-2">
    <div class="card-body">
        <form (ngSubmit)="create()" [formGroup]="form">
        <div class="row">
            <div class="form-group col-lg-6">
              <label>Perfil</label>
              <input type="text" class="form-control" formControlName="name">
              <span class="text-danger" *ngIf="form.controls.name.invalid && form.controls.name.dirty">
                <p *ngIf="form.controls.name.errors.required">Nome é requerido!</p>
                <p *ngIf="form.controls.name.errors.rangeLength">O nome pode ter no maximo 100 caracteres!</p>
              </span>
            </div>
            <div class="form-group col-lg-6">
                <label>Permissões</label>
                <ng-multiselect-dropdown [placeholder]="'Selecionar '" [settings]="dropdownSettings" [data]="roles"
                  [(ngModel)]="selectedFunctions" [ngModelOptions]="{standalone: true}" (onSelect)="onItemSelect($event)">
                </ng-multiselect-dropdown>
              </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-12">
              <label>Descrição</label>
              <textarea type="text" class="form-control" formControlName="description"></textarea>
              <span class="text-danger" *ngIf="form.controls.description.invalid && form.controls.description.dirty">
                <p *ngIf="form.controls.description.errors.required">Descrição é requerida!</p>
              </span>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12">
              <button type="submit" class="btn btn-success btn-block" [disabled]="form.invalid">Criar permissão</button>
            </div>
        </div>
      </form>
    </div>
  </div>