import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mercado-pago-logs-card',
  templateUrl: './mercado-pago-logs-card.component.html',
  styleUrls: ['./mercado-pago-logs-card.component.scss']
})
export class MercadoPagoLogsCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
