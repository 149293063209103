<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">Dashboard</li>
    <li class="breadcrumb-item" aria-current="page">
      <a [routerLink]="['/pages/user-list']">Usuários</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
  </ol>
</nav>

<div class="card mb-3 col-sm-12 col-md-6 offset-md-2">
  <div class="card-body">
    <form (ngSubmit)="create()" [formGroup]="form" class="row">
      <div class="form-group  col-md-6">
        <label>Name</label>
        <input type="text" class="form-control" formControlName="name">
        <span class="text-danger" *ngIf="form.controls.name.invalid && form.controls.name.dirty">
          <p *ngIf="form.controls.name.errors.required">O nome é requerido!</p>
          <p *ngIf="form.controls.name.errors.rangeLength">O nome precisa ter mais de 3 caracteres!</p>
        </span>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label>E-mail</label>
        <input type="text" class="form-control" formControlName="email">
        <span class="text-danger" *ngIf="form.controls.email.invalid && form.controls.email.dirty">
          <p *ngIf="form.controls.email.errors.required">E-mail é requerido!</p>
          <p *ngIf="form.controls.email.errors.email">E-mail é invalido!</p>
        </span>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label>Senha</label>
        <input type="password" class="form-control" formControlName="password">
        <span class="text-danger" *ngIf="form.controls.password.invalid && form.controls.password.dirty">
          <p *ngIf="form.controls.password.errors.required">Senha é requerida!</p>
          <p *ngIf="form.controls.password.errors.rangeLength">A senha deve ter de 6 a 20 caracteres!</p>
        </span>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label>Confirme a senha</label>
        <input type="password" class="form-control" formControlName="confirmPassword">
        <span class="text-danger"
          *ngIf="form.controls.confirmPassword.dirty && form.controls.password.value !== form.controls.confirmPassword.value">As senhas devem ser iguais!</span>
      </div>
      <div class="form-group col-sm-12">
        <label>Permissões</label>
        <ng-multiselect-dropdown [placeholder]="'Selecionar permissões'" [settings]="dropdownSettings" [data]="roles"
          [(ngModel)]="selectedRoles" [ngModelOptions]="{standalone: true}" (onSelect)="onItemSelect($event)">
        </ng-multiselect-dropdown>
      </div>
      <div class="form-group col-sm-12">
        <button type="submit" class="btn button-color btn-block" [disabled]="form.invalid">Criar usuário</button>
      </div>
    </form>
  </div>
</div>