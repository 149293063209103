import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RoleGetResponseDto } from 'src/app/dtos/role-get-response.dto';
import { RoleRegisterRequestDto } from 'src/app/dtos/role-register-request.dto';
import { RoleRegisterResponseDto } from 'src/app/dtos/role-register-response.dto';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-roles-new',
  templateUrl: './roles-new.component.html',
  styleUrls: ['./roles-new.component.scss']
})
export class RolesNewComponent implements OnInit {
  
  form: FormGroup;
  roles: RoleGetResponseDto[];
  dropdownSettings: IDropdownSettings = {};
  selectedFunctions = [];
  request: RoleRegisterRequestDto;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private roleService: RoleService,
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      functions: ['', [Validators.required]]
    });

  }
  
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 5,
      allowSearchFilter: false,
      enableCheckAll: false
    }
    this.getRoles()
  }

  getRoles() {
    this.roleService.list().subscribe(
      success => this.runSuccessListRoles(success),
      error => this.runError(error)
    )
  }

  onItemSelect(event) {

    const roles: string[] = [];

    roles.push(event.name);
    this.form.patchValue({
      functions: roles
    });
  }

  runSuccessListRoles(success: RoleGetResponseDto[]) {
    this.roles = success;
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
    }
  }

  async create() {
    this.ngxSpinnerService.show();

    this.form.value.functions = this.selectedFunctions.map(r => r.name);
    this.form.value.confirmPassword = '';

    this.request = Object.assign({}, this.form.value);

    this.roleService.register(this.request).subscribe(
      success => this.runSuccessCreateUser(success),
      error => this.runError(error)
    );
  }

  runSuccessCreateUser(success: RoleRegisterResponseDto) {
    const toastr = this.toastrService.success('Role Created', 'Success', {
      progressBar: true
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/pages/roles');
      });
    }
  }

}
