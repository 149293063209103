import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'ngx-custom-validators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WalletUpdateRequestDto } from '../../dtos/wallet-update-request.dto';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-wallet-edit',
  templateUrl: './wallet-edit.component.html',
  styleUrls: ['./wallet-edit.component.scss']
})
export class WalletEditComponent implements OnInit {

  form: FormGroup;
  request: WalletUpdateRequestDto;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private walletService: WalletService,
  ) {
    this.form = this.fb.group(
      {
        _id: ['', [Validators.required]],
        nome: ['', [Validators.required, CustomValidators.rangeLength([3, 100])]],
        taxa: ['', [Validators.required]],
        posFixado: ['', [Validators.required]],
        preFixado: ['', [Validators.required]],
        inflacao: ['', [Validators.required]],
        rfGlobal: ['', [Validators.required]],
        multimercado: ['', [Validators.required]],
        rvBrasil: ['', [Validators.required]],
        rvGlobal: ['', [Validators.required]],
        alternativos: ['', [Validators.required]],
      },
    );
  }

  ngOnInit(): void {
    
    const wallet = this.route.snapshot.data['wallet'];

    this.form.patchValue({
      _id: wallet._id,
      taxa: wallet.taxa,
      nome: wallet.nome,
      posFixado:  wallet.posFixado,
      preFixado:  wallet.preFixado,
      inflacao:  wallet.inflacao,
      rfGlobal:  wallet.rfGlobal,
      multimercado:  wallet.multimercado,
      rvBrasil:  wallet.rvBrasil,
      rvGlobal:  wallet.rvGlobal,
      alternativos:  wallet.alternativos,
    });
  }

  async update() {

    this.ngxSpinnerService.show();

    this.request = Object.assign({}, this.form.value);

    this.walletService.update(this.form.controls._id.value, this.request).subscribe(
      (success) => this.runSuccessRegister(success),
      (error) => this.runError(error)
    );
  }

  runSuccessRegister(success: WalletUpdateRequestDto) {
    const toastr = this.toastrService.success('Carteira atualizada com sucesso!', 'Sucesso', {
      progressBar: true,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/pages/wallet');
      });
    }
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
    }
  }
}
