import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { UserGetResponseDto } from "../dtos/user-get-response.dto";
import { UserRegisterRequestDto } from "../dtos/user-register-request.dto";
import { UserRegisterResponseDto } from "../dtos/user-register-response.dto";
import { UserResetPasswordRequestDto } from "../dtos/user-reset-password-request.dto";
import { UserUpdateRequestDto } from "../dtos/user-update-request.dto";
import { UserUpdateResponseDto } from "../dtos/user-update-response.dto";
import { BaseService } from "./base.service";

@Injectable()
export class UserService extends BaseService {

    url: string = `${environment.Apis.accessControl}/access-control/super-user`;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();
    }

    list(): Observable<UserGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getById(_id: string): Observable<UserGetResponseDto> {
        return this.httpClient
            .get(`${this.url}/id/${_id}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getByEmail(email: string): Observable<UserGetResponseDto> {
        return this.httpClient
            .get(`${this.url}/email/${email}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    register(dto: UserRegisterRequestDto): Observable<UserRegisterResponseDto> {
        return this.httpClient
            .post(`${this.url}`, dto, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    update(dto: UserUpdateRequestDto): Observable<UserUpdateResponseDto> {
        return this.httpClient
            .put(`${this.url}/id/${dto._id}`, dto, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    resetPassword(dto: UserResetPasswordRequestDto): Observable<any> {
        return this.httpClient
            .put(`${this.url}/update-password`, dto, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
