import { UserGetResponseDto } from 'src/app/dtos/user-get-response.dto';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class UserEmailResolve implements Resolve<UserGetResponseDto> {

    constructor(
        private userService: UserService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.userService.getByEmail(route.params['email']);
    }
}