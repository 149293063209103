import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { DepositGetResponseDto } from "../dtos/deposit-get-response.dto";
import { BaseService } from "./base.service";
import { catchError, map } from 'rxjs/operators';
import { DepositStatusEnum } from "../dtos/deposit-status.enum";
import { DepositUpdateStatusRequestDto } from "../dtos/deposit-update-status-request.dto";
import { DepositUpdateStatusResponseDto } from "../dtos/deposit-update-status-response.dto";
import { DepositDennyRequestDto } from "../dtos/deposit-denny-request.dto";
import { DepositDennyResponseDto } from "../dtos/deposit-denny-response.dto";


@Injectable()
export class DepositService extends BaseService {

    // url: string = environment.Apis.deposit;

    // constructor(
    //     private httpClient: HttpClient,
    // ) {
    //     super();
    // }

    // listByStatus(status: DepositStatusEnum): Observable<DepositGetResponseDto[]> {
    //     return this.httpClient
    //         .get(`${this.url}/deposit/status/${status}`, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }

    // count(status: DepositStatusEnum): Observable<any> {
    //     return this.httpClient
    //         .get(`${this.url}/deposit/status/${status}/count`, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }

    // getById(id: string): Observable<DepositGetResponseDto> {
    //     return this.httpClient
    //         .get(`${this.url}/deposit/id/${id}`, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }

    // updateStatus(_id: string, dto: DepositUpdateStatusRequestDto): Observable<DepositUpdateStatusResponseDto> {
    //     return this.httpClient
    //         .put(`${this.url}/deposit/update-status/id/${_id}`, dto, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }
    
    // dennyStatus(_id: string, dto: DepositDennyRequestDto): Observable<DepositDennyResponseDto> {
    //     return this.httpClient
    //         .put(`${this.url}/deposit/denny-status/id/${_id}`, dto, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }

}
