import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AuthenticateRequestDto } from "../dtos/authenticate-request.dto";
import { BaseService } from "./base.service";
import { catchError, map } from 'rxjs/operators';
import { UserStorageDto } from "../dtos/user-storage.dto";
import { LocalStorageKeys } from "../utils/localstorage-util";
import { Observable } from "rxjs";
import { AuthenticateResponseDto } from "../dtos/authenticate-response.dto";
import { ValidateUserResponseDto } from "../dtos/validate-user-response.dto";

@Injectable()
export class AuthService extends BaseService {

    url: string = environment.Apis.accessControl;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();
    }

    authenticate(dto: AuthenticateRequestDto): Observable<AuthenticateResponseDto> {

        return this.httpClient
            .post(`${this.url}/access-control/auth`, dto, this.anonymousHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    validateUser(password: string): Observable<ValidateUserResponseDto> {
        const email = ((this.LocalStorage.get(LocalStorageKeys.user)).email).toString()
        return this.httpClient
            .post(`${this.url}/access-control/auth/validate`, { email: email, password: password }, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    setAuthenticatedUser(dto: UserStorageDto) {
        this.LocalStorage.set(LocalStorageKeys.user, dto);
    }

    getAuthenticatedUser(): UserStorageDto {
        return this.LocalStorage.get(LocalStorageKeys.user);
    }



    logout(): void {
        this.LocalStorage.remove(LocalStorageKeys.user)
    }
}