import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FunctionEnum } from 'src/app/dtos/function.enum';
import { FunctionsRoleDto } from 'src/app/dtos/functions-role.dto';
import { RoleGetResponseDto } from 'src/app/dtos/role-get-response.dto';
import { RoleRegisterRequestDto } from 'src/app/dtos/role-register-request.dto';
import { RoleRegisterResponseDto } from 'src/app/dtos/role-register-response.dto';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-roles-edit',
  templateUrl: './roles-edit.component.html',
  styleUrls: ['./roles-edit.component.scss']
})
export class RolesEditComponent implements OnInit {

 
  form: FormGroup;
  functions: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  selectedFunctions = [];
  request: RoleRegisterRequestDto;
  role: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private roleService: RoleService,
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      functions: ['', [Validators.required]]
    });

  }
  
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'name',
      textField: 'name',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 5,
      allowSearchFilter: false,
      enableCheckAll: false
    }
    this.role = this.route.snapshot.data['role'];
    this.form.get('name').setValue(this.role.name)
    this.form.get('description').setValue(this.role.description)
    this.getfunctions()
    // this.getSelecteFunctions()

  }

  getfunctions() {

     Object.keys(FunctionEnum).forEach((item)=>{
      this.functions.push({name: item})  
    })

    this.role.functions.forEach(item => {
      this.selectedFunctions.push({name: item})
      this.form.patchValue({
        functions: item
      })
    });
  }

  onItemSelect(event) {
    const funcitons: string[] = [];
    funcitons.push(event.name);
    this.form.patchValue({
      functions: funcitons
    });

  }

  // runSuccessListRoles(success: RoleGetResponseDto[]) {
  //   this.functions = success;
  // }


  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
    }
  }



  async create() {
    this.ngxSpinnerService.show();

    this.form.value.functions = this.selectedFunctions.map(r => r.name);

    this.request = Object.assign({}, this.form.value);
    //console.log(this.request);

    this.roleService.update(this.role._id,this.request).subscribe(
      success => this.runSuccessCreateUser(),
      error => this.runError(error)
    );
  }

  runSuccessCreateUser() {
    const toastr = this.toastrService.success('Role Updated', 'Success', {
      progressBar: true
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/pages/roles');
      });
    }
  }


}
