
<div class="card mb-3 col-sm-12 col-md-4 offset-md-4">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <h5 class="text-center">Informação do usuário</h5>
      </div>
      <div class="col-sm-12">
        <span><strong>Nome: </strong>{{user.name}}</span>
      </div>
      <div class="col-sm-12">
        <span><strong>E-mail: </strong>{{user.email}}</span>
      </div>
    </div>
    <hr>
    <form (ngSubmit)="save()" [formGroup]="form" class="row">
      <div class="col-sm-12">
        <h5 class="text-center">Alterar a senha</h5>
      </div>
      <div class="form-group col-sm-12">
        <label for="name">Senha atual</label>
        <input type="password" class="form-control" formControlName="previousPassword">
      </div>
      <div class="form-group col-sm-12">
        <label for="name">Nova senha</label>
        <input type="password" class="form-control" formControlName="newPassword">
      </div>
      <div class="form-group col-sm-12">
        <label for="email">Confirmar nova senha</label>
        <input type="password" class="form-control" formControlName="confirmNewPassword">
        <span 
          class="text-danger" 
          *ngIf="form.controls.newPassword.value !== form.controls.confirmNewPassword.value && !form.controls.confirmNewPassword.pristine"
        >As senhas não são iguais</span>
      </div>

      <button 
        type="submit" 
        class="btn button-color btn-block" 
        [disabled]="!isValidPasswordUpdate()"
      >Alterar</button>
    </form>
  </div>
</div>