import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-size-selector',
  templateUrl: './table-size-selector.component.html',
  styleUrls: ['./table-size-selector.component.scss']
})
export class TableSizeSelectorComponent  {

  @Output() tableSizeChange = new EventEmitter<number>();

  changeTableSize(size: number) {
    this.tableSizeChange.emit(size);
  }

}
