import { UserNewComponent } from './user-new/user-new.component';
import { TfaResolve } from '../resolvers/tfa.resolve';
import { UserResolve } from '../resolvers/user.resolve';
import { UserResetPasswordComponent } from './user-reset-password/user-reset-password.component';
import { UserTfaComponent } from './user-tfa/user-tfa.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserListComponent } from './user-list/user-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckDepositsComponent } from './check-deposits/check-deposits.component';
import { CheckKycComponent } from './check-kyc/check-kyc.component';
import { CheckRebuyComponent } from './check-rebuy/check-rebuy.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagesComponent } from './pages.component';
import { AuthenticationGuard } from '../guards/authentication.guard';
import { CheckKycWhitelistComponent } from './check-kyc-whitelist/check-kyc-whitelist.component';
import { UserEmailResolve } from '../resolvers/user-email.resolve';
import { RolesComponent } from './roles/roles.component';
import { RolesNewComponent } from './roles-new/roles-new.component';
import { RolesEditComponent } from './roles-edit/roles-edit.component';
import { RolesResolve } from '../resolvers/roles.resolve';
import { WalletListComponent } from './wallet-list/wallet-list.component';
import { WalletNewComponent } from './wallet-new/wallet-new.component';
import { WalletEditComponent } from './wallet-edit/wallet-edit.component';
import { WalletResolve } from '../resolvers/wallet.resolve';
import { ViewClientsExportedComponent } from './view-customers-exported/view-customers-exported.component';
import { RemoveCustomersComponent } from './remove-customers/remove-customers.component';
import { ProgressCustomersComponent } from './progress-customers/progress-customers.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { LogsComponent } from "./logs/logs.component";
import { PurchaseLogsComponent } from "./purchase-logs/purchase-logs.component";
import { MercadoPagoLogsComponent } from "./mercado-pago-logs/mercado-pago-logs.component";
import { AdvisorResumeComponent } from './advisor-resume/advisor-resume.component';
import { BuysGoldResumeComponent } from './buys-gold-resume/buys-gold-resume.component';
import { OperatingSystemsComponent } from "./operating-systems/operating-systems.component";

const pagesRoute: Routes = [
  {
    path: '', component: PagesComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard] },
      { path: 'view-customers-active', component: CheckDepositsComponent, canActivate: [AuthenticationGuard] },
      { path: 'view-customers-active/view-customers-exported', component: ViewClientsExportedComponent, canActivate: [AuthenticationGuard]},
      { path: 'notifications', component: NotificationsComponent, canActivate: [AuthenticationGuard] },
      { path: 'operating-systems', component: OperatingSystemsComponent, canActivate: [AuthenticationGuard] },
      {
        path: 'user-list',
        component: UserListComponent, canActivate: [AuthenticationGuard]
      },
      {
        path: 'user-new',
        component: UserNewComponent, canActivate: [AuthenticationGuard]
      },
      {
        path: 'user-edit/:userId',
        component: UserEditComponent,
        resolve: {
          user: UserResolve
        }, canActivate: [AuthenticationGuard]
      },
      {
        path: 'user-tfa',
        component: UserTfaComponent,
        resolve: {
          tfa: TfaResolve
        }, canActivate: [AuthenticationGuard]
      },
      {
        path: 'user-reset-password/:email',
        component: UserResetPasswordComponent,
        resolve: {
          user: UserEmailResolve
        },
        canActivate: [AuthenticationGuard]
      },
      { path: 'user-new', component: UserNewComponent, canActivate: [AuthenticationGuard] },
      { path: 'roles', component: RolesComponent, canActivate: [AuthenticationGuard] },
      { path: 'roles-new', component: RolesNewComponent, canActivate: [AuthenticationGuard] },
      {
        path: 'roles-edit/:id',
        component: RolesEditComponent,
        resolve: { role: RolesResolve },
        canActivate: [AuthenticationGuard]
      },
      { path: 'waiting-customers', component: CheckRebuyComponent, canActivate: [AuthenticationGuard] },
      { path: 'customers-inactive', component: CheckKycComponent, canActivate: [AuthenticationGuard] },
      { path: 'remove-customers', component: RemoveCustomersComponent, canActivate: [AuthenticationGuard] },
      { path: 'progress-customers', component: ProgressCustomersComponent, canActivate: [AuthenticationGuard] },
      { path: 'check-kyc-whitelist', component: CheckKycWhitelistComponent, canActivate: [AuthenticationGuard] },
      { path: 'wallet', component: WalletListComponent, canActivate: [AuthenticationGuard] },
      { path: 'wallet-new', component: WalletNewComponent, canActivate: [AuthenticationGuard] },
      { path: 'wallet-edit/:_id', component: WalletEditComponent, resolve: { wallet: WalletResolve }, canActivate: [AuthenticationGuard] },
      { path: 'logs', component: LogsComponent, canActivate: [AuthenticationGuard] },
      { path: 'purchase-logs', component: PurchaseLogsComponent, canActivate: [AuthenticationGuard] },
      { path: 'mercado-pago-logs', component: MercadoPagoLogsComponent, canActivate: [AuthenticationGuard] },
      { path: 'advisor-resume', component: AdvisorResumeComponent, canActivate: [AuthenticationGuard] },
      { path: 'buys-gold-resume', component: BuysGoldResumeComponent, canActivate: [AuthenticationGuard] },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(pagesRoute)
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule { }