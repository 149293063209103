<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a [routerLink]="['/pages/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item" aria-current="page">
            <a [routerLink]="['/pages/logs']">Análise de logs</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Mercado Pago</li>
    </ol>
</nav>
<div class="card card-radius mb-3">
    <div class="card-body">

        <div class="form-group">
            <label for="term">Buscar</label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Digite o texto para buscar" [(ngModel)]="term">
            </div>
        </div>
    </div>
</div>
<div class="card card-radius mt-3">
    <div class="card-body">
        <div class="buttons-export d-flex">
            <div class="w-50 d-flex justify-content-start">
                <!-- <a (click)="exportNamePhone()" class="btn btn-outline-secondary">Exportar nome e telefone</a> -->
            </div>
            <div class="w-50 d-flex justify-content-end">
                <a (click)="selectAll()" class="btn btn-outline-secondary mr-2">Selecionar todos</a>
                <a (click)="exportFluxoFinanceiro()" class="btn btn-outline-secondary">Exportar selecionados</a>
            </div>
        </div>
        <div class="table-responsive card-radius">
            <table class="table table-sm table-hover table-striped">
                <thead class="button-color">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Nome</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Telefone</th>
                        <th scope="col">Compra recusada</th>
                        <th scope="col">Compra em análise</th>
                        <th scope="col">Premium?</th>
                        <th scope="col">Data Projeto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of mercadoPagoTable | filter:term | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }; let i= index">
                        <td><input (change)="selectedClient($event, item)"
                                [checked]="isChecked" type="checkbox">
                        </td>
                        <td [ngClass]="item.name ? '' : 'empty-field'">{{item.name ? item.name : '*'}}</td>
                        <td [ngClass]="item.email ? '' : 'empty-field'">{{item.email ? item.email : '*'}}</td>
                        <td [ngClass]="item.phone ? '' : 'empty-field'">{{item.phone ? item.phone : '*'}}</td>
                        <td [ngClass]="item.failurePurchase ? '' : 'empty-field'">{{item.failurePurchase ? item.failurePurchase : '*'}}</td>
                        <td [ngClass]="item.pendingPurchase ? '' : 'empty-field'">{{item.pendingPurchase ? item.pendingPurchase : '*'}}
                        </td>
                        <td [ngClass]="item.premium ? '' : 'empty-field'">
                            <ng-container *ngIf="item.premium;else noPremium">
                                <i class="fas fa-check text-success"></i>
                            </ng-container>
                            <ng-template #noPremium>
                                <i class="fas fa-times text-danger"></i>
                            </ng-template>
                        </td>
                        <td [ngClass]="item.phone ? '' : 'empty-field'">{{item.createdAt ? item.createdAt : '*'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls (pageChange)="currentPage = $event" previousLabel="Anterior" nextLabel="Próximo">
            </pagination-controls>
        </div>
    </div>
</div>