import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AuthenticateRequestDto } from "../dtos/authenticate-request.dto";
import { BaseService } from "./base.service";
import { catchError, map } from 'rxjs/operators';
import { UserStorageDto } from "../dtos/user-storage.dto";
import { LocalStorageKeys } from "../utils/localstorage-util";
import { Observable, of } from "rxjs";
import { AuthenticateResponseDto } from "../dtos/authenticate-response.dto";
import { TfaGenerateResponseDto } from "../dtos/tfa-generate-response.dto";
import { TfaGetResponseDto } from "../dtos/tfa-get-response.dto";
import { TfaRegisterRequestDto } from "../dtos/tfa-register-request.dto";
import { TfaRegisterResponseDto } from "../dtos/tfa-register-response.dto";
import { TfaVerifyRequestDto } from "../dtos/tfa-verify-request.dto";
import { TfaVerifyResponseDto } from "../dtos/tfa-verify-response.dto";
import { TfaVerifyRegisteredRequestDto } from "../dtos/tfa-verify-registered-request.dto";

@Injectable()
export class TfaService extends BaseService {

    url: string = environment.Apis.accessControl;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();
    }

    generateQrCode(): Observable<any> {
        return this.httpClient
            .post(`${this.url}/access-control/2fa/generate-qrcode`, {}, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    generateTfa(): Observable<TfaGenerateResponseDto> {
        return this.httpClient
            .post(`${this.url}/access-control/2fa/generate`, {}, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    registerTfa(dto: TfaRegisterRequestDto): Observable<TfaRegisterResponseDto> {
        return this.httpClient
            .post(`${this.url}/access-control/2fa/register`, dto, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    getTfa(): Observable<TfaGetResponseDto> {
        return this.httpClient
            .get(`${this.url}/access-control/2fa`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    verifyTfa(dto: TfaVerifyRequestDto): Observable<TfaVerifyResponseDto> {
        return this.httpClient
            .post(`${this.url}/access-control/2fa/verify`, dto, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    verifyRegisteredTfa(dto: TfaVerifyRegisteredRequestDto): Observable<TfaVerifyResponseDto> {
        return this.httpClient
            .post(`${this.url}/access-control/2fa/verify-registered`, dto, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    deleteTfa(): Observable<TfaRegisterResponseDto> {
        return this.httpClient
            .delete(`${this.url}/access-control/2fa`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
