import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RegisterNotificationDTO } from "../dtos/register-notification.dto";

@Injectable()
export class PushService extends BaseService {

    url: string = environment.Apis.accessControl;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();
    }

    sendNotification(notification: RegisterNotificationDTO): Observable<any> {
        return this.httpClient
            .post(`${this.url}/client/push/send-notification`, notification, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getNotifications(): Observable<any> {
        return this.httpClient
            .get(`${this.url}/client/push/`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}