import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const timeoutValue = 300000; // Timeout de 10 segundos (10000 ms)

    return next.handle(request).pipe(
      timeout(timeoutValue),
      catchError((error) => {
        console.log("Timeout for request: ", error)

        return throwError(error);
      })
    );
  }
}
