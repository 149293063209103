import { UserResetPasswordRequestDto } from '../../dtos/user-reset-password-request.dto';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserGetResponseDto } from 'src/app/dtos/user-get-response.dto';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import * as bcryptjs from 'bcryptjs';

@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss']
})
export class UserResetPasswordComponent implements OnInit {

  user: UserGetResponseDto;

  request: UserResetPasswordRequestDto = {
    previousPassword: '',
    newPassword: '',
  }

  form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private fb: FormBuilder,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user = this.route.snapshot.data['user'];

    this.form = this.fb.group({
      previousPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      confirmNewPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
    }, { validator: this.checkPasswordConfirmation });
  }

  private checkPasswordConfirmation(group: FormGroup) {
    let password = group.get('newPassword').value;
    let confirmPassword = group.get('confirmNewPassword').value;
    return password === confirmPassword ? null : { notSame: true }
  }

  async save() {
    try {
      if (this.isValidPasswordUpdate()) {
        this.ngxSpinnerService.show();
        this.request.previousPassword = this.form.value.previousPassword;
        this.request.newPassword = this.form.value.newPasswordz;
        this.userService.resetPassword(this.request).subscribe(
          sucesss => this.runSuccessResetPassword(sucesss),
          error => this.runError(error)
        )
      }
    } catch (error) { }
  }

  runSuccessResetPassword(success: UserGetResponseDto) {
    const toastr = this.toastrService.success('User password reseted', 'Success', {
      progressBar: true
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('pages/dashboard');
      });
    }
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.errors[0], 'Error', {
      progressBar: true
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
    }
  }

  isValidPasswordUpdate(): boolean {
    return this.form.value.previousPassword && this.form.value.newPassword && this.form.value.confirmNewPassword &&
      (this.form.value.newPassword == this.form.value.confirmNewPassword)
  }
}
