import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { BaseService } from "./base.service";

@Injectable()
export class CcoinService extends BaseService {

    // url: string = environment.Apis.core;

    // constructor(
    //     private httpClient: HttpClient
    // ) {
    //     super();
    // }

    // mint(address: string, amount: string): Observable<any> {

    //     const body = {
    //         address,
    //         amount
    //     };

    //     return this.httpClient
    //         .post(`${this.url}/ccoin/mint`, body, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }

    // burn(address: string, amount: string): Observable<any> {

    //     const body = {
    //         address,
    //         amount
    //     };

    //     return this.httpClient
    //         .post(`${this.url}/ccoin/burn`, body, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }

    // approve(spender:string, amount: string): Observable<any> {

    //     const body = {
    //         spender,
    //         amount
    //     };

    //     return this.httpClient
    //         .post(`${this.url}/ccoin/approve`, body, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }

    // increaseAllowance(amount: string): Observable<any> {

    //     const body = {
    //         amount,
    //     };

    //     return this.httpClient
    //         .post(`${this.url}/ccoin/increase-allowance`, body, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }
}