import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RoleGetResponseDto } from 'src/app/dtos/role-get-response.dto';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  formSearch: FormGroup;
  currentPage: number = 1;
  itensPerPage: number = 20;
  list: RoleGetResponseDto[] = [];
  deleteId: string;
  role: any;
  term: string = '';
  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private roleService: RoleService,
    private fb: FormBuilder
  ) {
    this.formSearch = this.fb.group({
      term: ['']
    })
  }

  ngOnInit(): void {

    this.ngxSpinnerService.show();
    this.listRoles();
  }


  listRoles() {
    this.roleService.list().subscribe(
      success => this.runListRolesSuccess(success),
      error => console.error(error)
    )
  }

  selectIdDelete(id: string) {
    this.deleteId = id;
  }

  delete() {
    this.ngxSpinnerService.show();
    this.roleService.delete(this.deleteId).subscribe(
      success => this.runDeleteRolesSuccess(),
      error => this.runError(error)
    )
  }
  runDeleteRolesSuccess(): void {
    const toastr = this.toastrService.success("Role has been deleted", "Success", {
      progressBar: true
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.deleteId = null;
        this.listRoles()
        this.ngxSpinnerService.hide();
      })
    }
  }
  search() {
    const term: string = this.formSearch.controls.term.value;

    if (term)
      this.list = this.list.filter(a => a.name.includes(term));
    else {
      this.runError("Role not found!")
      this.listRoles();
    }

  }

  runListRolesSuccess(success: RoleGetResponseDto[]): void {
    this.list = success;
    this.ngxSpinnerService.hide();
  }


  runError(exception: any) {
    const toastr = this.toastrService.error(exception, 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.deleteId = null;
        this.ngxSpinnerService.hide();
      });
  }

}
