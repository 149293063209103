import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-pgc-dashboard-card',
  templateUrl: './pgc-dashboard-card.component.html',
  styleUrls: ['./pgc-dashboard-card.component.scss']
})
export class PgcDashboardCardComponent implements OnInit {

  count: number = 0;

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit(){
   this.countTotalClients();
  }
  
  countTotalClients() {
    this.clientService.countAll().subscribe(
      success => this.count = success,
      error => console.error(error)
    )
  }

}
