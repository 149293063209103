import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { BaseService } from "./base.service";
import { catchError, map } from 'rxjs/operators';
import { Observable } from "rxjs";

@Injectable()
export class LogsService extends BaseService {

    private url: string = environment.Apis.logs;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();
    }

    async getAll(): Promise<any> {
        return await fetch(`${this.url}/purchase-sequence/getAll`);
    }

    async getPurchaseTableData(): Promise<any> {
        return await fetch(`${this.url}/purchase-sequence/table-data`);
    }

    async getMercadoPagoTableData(): Promise<any> {
        return await fetch(`${this.url}/mercado-pago/table-data`);
    }



}