import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MessageRegisterRequestDto } from 'src/app/dtos/message-register-request.dto';
import { RebuyGetResponseDto } from 'src/app/dtos/rebuy-get-response.dto';
import { RebuyStatusEnum } from 'src/app/dtos/rebuy-status.enum';
import { RebuyUpdateStatusRequestDto } from 'src/app/dtos/rebuy-update-status-request.dto';
import { CcoinService } from 'src/app/services/ccoin.service';
import { MessageService } from 'src/app/services/message.service';
import { RebuyService } from 'src/app/services/rebuy.service';
import { KycGetResponseDto } from '../../dtos/kyc-get-response.dto';
import { GlobalService } from '../../services/global.service';
import { KycService } from '../../services/kyc.service';

@Component({
  selector: 'app-check-rebuy-detail',
  templateUrl: './check-rebuy-detail.component.html',
  styleUrls: ['./check-rebuy-detail.component.scss']
})
export class CheckRebuyDetailComponent implements OnInit {
  rebuy_id: any;
  rebuy: RebuyGetResponseDto;
  list: KycGetResponseDto[];
  kyc: KycGetResponseDto;

  constructor(
    private rebuyService: RebuyService,
    private kycService: KycService,
    private route: ActivatedRoute,
    private ccoinService: CcoinService,
    private messageService: MessageService,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router,
    private globalService: GlobalService,
  ) { }

  ngOnInit(): void {
    this.rebuy = this.route.snapshot.data['rebuy'];
    this.getList();
  }

  runError(message: string) {
    const toastr = this.toastrService.error(message, 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.rebuy = null;
        this.ngxSpinnerService.hide();
      });
  }

  getList() {
    // this.kycService.listVerified().subscribe(
    //   success => {
    //     this.kyc = success.find(a => a.walletAddress == this.rebuy.address)
    //   },
    //   error => console.error(error)
    // )
  }

  approve() {
    // this.ngxSpinnerService.show();

    // this.ccoinService.burn(this.rebuy.address, this.rebuy.amountCoin.toString()).subscribe(
    //   success => this.runBurnSuccess(success),
    //   () => this.runError('Error executing burn')
    // );
  }

  runBurnSuccess(response: any): void {

    // console.log('burn success', response);

    // this.toastrService.success(`Burn transaction ${response.transaction} created`, 'Success', {
    //   progressBar: true
    // });

    // this.rebuyService.updateStatus(this.rebuy._id, new RebuyUpdateStatusRequestDto(RebuyStatusEnum.approve)).subscribe(
    //   () => this.runApprovedSuccess(),
    //   () => this.runError('Error updating deposit status')
    // );
  }

  runApprovedSuccess(): void {
    // this.messageService.register(
    //   this.rebuy.address,
    //   new MessageRegisterRequestDto(
    //     'Confirmed rebuy',
    //     `Rebuy of ${this.rebuy.amountCoin} USD was made to wallet with address ${this.rebuy.address}`)).subscribe(
    //       () => this.runRegisterMessageSuccess(),
    //       () => this.runError('Error sending message')
    //     );
  }

  runRegisterMessageSuccess(): void {
    const toastr = this.toastrService.success('Rebuy approved', 'Success', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.rebuy = null;
        this.router.navigateByUrl('/pages/check-rebuy')
        this.ngxSpinnerService.hide();
      });
  }
  disapprove() {
    // this.ngxSpinnerService.show();

    // this.rebuyService.updateStatus(this.rebuy._id, new RebuyUpdateStatusRequestDto(RebuyStatusEnum.notApprove)).subscribe(
    //   () => this.runDenySuccess(),
    //   () => this.runError('Error updating deposit status')
    // );
  }

  runDenySuccess(): void {
    // this.messageService.register(this.rebuy.address,
    //   new MessageRegisterRequestDto('Rebuy Denied', `Rebuy of ${this.rebuy.amountCoin} USD was not approved. Please, try again`)).subscribe(
    //     success => this.runRegisterMessageDennySuccess(success),
    //     error => this.runError('Error sending message')
    //   )
  }

  runRegisterMessageDennySuccess(success: any) {
    const toastr = this.toastrService.success('Rebuy Denied', 'Success', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.router.navigateByUrl('/pages/check-rebuy')
        this.ngxSpinnerService.hide();
      });
  }
}
