
<div *ngIf="!tfaRegistered" class="card col-sm-12">
    <div class="card-body">
        <div class="row">
            <div class="col-sm-12">
                <h5 class="text-center">Habilitar duplo fator de segurança 2FA</h5>
            </div>
            <div class="col-sm-12">
                <span><strong>Status: </strong>desabilitado</span>
            </div>
        </div>
        <hr>
        <form [formGroup]="form" (ngSubmit)="register()">
            <div class="row">
                <div class="col-md-6">
                    <div class="text-center">
                        <h6>Escaneie o código QRCode para gerar o duplo fator de segurança</h6>
                    </div>
                    <qrcode *ngIf="tfaQrCode && !tfaRegistered" class="text-center" [qrdata]="tfaQrCode" [width]="256"
                        [errorCorrectionLevel]="'M'"></qrcode>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" maxlength=6 class="form-control" id="code" placeholder="Digite o primeiro código"
                            formControlName="code"
                            [ngClass]="{ 'is-invalid': form.controls.code.invalid && form.controls.code.dirty, 'is-valid': form.controls.code.valid }">
                        <span class="text-danger" *ngIf="form.controls.code.invalid && form.controls.code.dirty">
                            <p *ngIf="form.controls.code.errors.required">O código é requerido</p>
                            <p *ngIf="form.controls.code.errors.pattern">Digite o código de 6 digitos</p>
                        </span>
                        <input type="password" class="form-control" id="password" placeholder="Sua senha"
                        formControlName="password" [ngClass]="{'is-invalid':form.controls.password.invalid && form.controls.password.dirty, 'is-valid': form.controls.password.valid}" />
                        <span class="text-danger" *ngIf="form.controls.password.invalid && form.controls.password.dirty && !form.controls.password.pristine">
                            <p *ngIf="form.controls.password.errors.required">Senha requerida</p>
                        </span>
                    </div>
                    <div class="form-group mt-3">
                        <button type="submit" class="btn btn btn-primary  btn-block" [disabled]="form.invalid">Habilitar</button>
                    </div>
                </div>
            </div>
           
            
        </form>
    </div>
</div>

<div *ngIf="tfaRegistered" class="card mb-3 col-sm-12 col-md-4 offset-md-4">
    <div class="card-body">
        <div class="row">
            <div class="col-sm-12">
                <h4 class="text-center">Desabilitar duplo fator de segurança 2FA</h4>
            </div>
            <div class="col-sm-12">
                <span><strong>Status: </strong>ativo
                    <hr>
                    <form [formGroup]="form" (ngSubmit)="disableTfa()">
                        <div class="text-center">
                            <h6>Digite o código autenticador para desativar o 2FA</h6>
                        </div>
                        <qrcode *ngIf="tfaQrCode" class="text-center" [qrdata]="tfaQrCode" [width]="256"
                            [errorCorrectionLevel]="'M'"></qrcode>
                        <div class="form-group">
                            <input type="text" maxlength=6 class="form-control" id="code" placeholder="Digite o código"
                                formControlName="code"
                                [ngClass]="{ 'is-invalid': form.controls.code.invalid && form.controls.code.dirty, 'is-valid': form.controls.code.valid }">
                            <span class="text-danger" *ngIf="form.controls.code.invalid && form.controls.code.dirty">
                                <p *ngIf="form.controls.code.errors.required">Código requerido</p>
                                <p *ngIf="form.controls.code.errors.pattern">Código de 6 digitos requerido</p>
                            </span>
                            <input type="password" class="form-control" id="password" placeholder="Senha"
                                formControlName="password" [ngClass]="{'is-invalid':form.controls.password.invalid && form.controls.password.dirty, 'is-valid': form.controls.password.valid}" />
                                <span class="text-danger" *ngIf="form.controls.password.invalid && form.controls.password.dirty && !form.controls.password.pristine">
                                    <p *ngIf="form.controls.password.errors.required">Sua senha é requerida</p>
                                </span>
                        </div>
                        <div class="form-group mt-3">
                            <button type="submit" class="btn btn button-color  btn-block"
                                [disabled]="form.invalid">Desabilitar</button>
                        </div>
                    </form>