<app-topbar></app-topbar>
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Not found</li>
    </ol>
</nav>
<div class="text-center">
    <div class="error mx-auto" style="height: 80px;" data-text="404">404</div>
    <p class="lead text-gray-800 mb-5">Page Not Found</p>
    <p class="text-gray-500 mb-0"> It was not possible to find the page</p>
    <a [routerLink]="['/pages']">&larr; Back to Dashboard</a>
</div>