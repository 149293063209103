import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { WalletGetResponseDto } from '../dtos/wallet-get-response.dto';
import { WalletService } from '../services/wallet.service';

@Injectable()
export class WalletResolve implements Resolve<WalletGetResponseDto> {

    constructor(
        private walletService: WalletService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.walletService.getById(route.params['_id']);
    }
}