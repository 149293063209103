import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

	currentUser = { 
		name: null,
		email: null
	};

	constructor(
        private router: Router,
		private authService: AuthService,
		private userService: UserService,
        ) {}


	ngOnInit(): void {
		this.showCurrentUser()
	}
	
	showCurrentUser(){
		let currentUserEmail: string;

		currentUserEmail = this.authService.getAuthenticatedUser().email

		this.userService.getByEmail(currentUserEmail).subscribe(
			success => {
				this.currentUser.name = success.name;
				this.currentUser.email = success.email;
			},
			error => console.log(error)
		)
	}

	signOut() {
		this.router.navigate(['/accounts/login']);
	}
}
