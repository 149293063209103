import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CashFlowGetResponseDto } from "../dtos/cash-flow-get-response.dto";

@Injectable()
export class CashFlowService extends BaseService {

    url: string = environment.Apis.accessControl;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();
    }

    get(email: string): Observable<CashFlowGetResponseDto> {
        return this.httpClient
            .get(`${this.url}/fluxo-financeiro/email/${email}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}