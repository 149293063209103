import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'ngx-custom-validators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WalletRegisterRequestDto } from '../../dtos/wallet-register-request.dto';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-wallet-new',
  templateUrl: './wallet-new.component.html',
  styleUrls: ['./wallet-new.component.scss']
})
export class WalletNewComponent {

  form: FormGroup;
  request: WalletRegisterRequestDto;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private walletService: WalletService,
  ) {
    this.form = this.fb.group(
      {
        nome: ['', [Validators.required, CustomValidators.rangeLength([3, 100])]],
        taxa: ['', [Validators.required]],
        posFixado: ['', [Validators.required]],
        preFixado: ['', [Validators.required]],
        inflacao: ['', [Validators.required]],
        rfGlobal: ['', [Validators.required]],
        multimercado: ['', [Validators.required]],
        rvBrasil: ['', [Validators.required]],
        rvGlobal: ['', [Validators.required]],
        alternativos: ['', [Validators.required]],
      },
    );
  }

  async create() {
    this.ngxSpinnerService.show();

    this.request = Object.assign({}, this.form.value);

    this.walletService.register(this.request).subscribe(
      (success) => this.runSuccessRegister(success),
      (error) => this.runError(error)
    );
  }

  runSuccessRegister(success: WalletRegisterRequestDto) {
    const toastr = this.toastrService.success('Carteira criada com sucesso!', 'Sucesso', {
      progressBar: true,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('/pages/wallet');
      });
    }
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
    }
  }
}