import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserGetResponseDto } from 'src/app/dtos/user-get-response.dto';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { UserUpdateResponseDto } from '../../dtos/user-update-response.dto';
import { CustomValidators } from 'ngx-custom-validators';
import { UserUpdateRequestDto } from '../../dtos/user-update-request.dto';
import { RoleGetResponseDto } from '../../dtos/role-get-response.dto';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  user: UserGetResponseDto;
  request: UserUpdateRequestDto;

  form: FormGroup;

  dropdownSettings: IDropdownSettings = {};
  roles: RoleGetResponseDto[];
  selectedRoles:RoleGetResponseDto [] = [];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router,
    private userService: UserService,
    private roleService: RoleService,
  ) { }

  ngOnInit(): void {
    this.ngxSpinnerService.show();

    this.user = this.route.snapshot.data['user'];
    this.selectedRoles = this.user.roles;

    this.form = this.fb.group({
      _id: [this.user._id, [Validators.required]],
      name: [this.user.name, [Validators.required, CustomValidators.rangeLength([3, 100])]],
      email: [this.user.email, [Validators.required, Validators.email]],
      roles: [this.user.roles, [Validators.required]],
      status: [this.user.status, [Validators.required]],
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: false,
      enableCheckAll: false
    }

    this.getRoles();

    this.ngxSpinnerService.hide();
  }

  getRoles() {
    this.roleService.list().subscribe(
      success => this.runSuccessListRoles(success),
      error => this.runError(error)
    );
  }

  runSuccessListRoles(success: RoleGetResponseDto[]) {
    this.roles = success;
  }


  save() {
    this.ngxSpinnerService.show();
    this.form.value.roles = this.selectedRoles.map(r => r._id);
    this.request = Object.assign({}, this.form.value);

    this.userService.update(this.request).subscribe(
      success => this.runSuccessUpdateUser(success),
      error => this.runError(error)
    );
  }

  runSuccessUpdateUser(success: UserUpdateResponseDto) {
    const toastr = this.toastrService.success('User updated', 'Success', {
      progressBar: true
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl('pages/user-list');
      });
    }
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.errors[0], 'Error', {
      progressBar: true
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
    }
  }
}
