<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <div>
        <p style="color: white"> Loading ...</p>
    </div>
</ngx-spinner>
<div class="d-flex" id="wrapper" [ngClass]="{toggled: isToogled}">
    <app-sidebar></app-sidebar>
    <div id="page-content-wrapper">
        <app-topbar></app-topbar>
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>