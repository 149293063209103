
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a [routerLink]="['/pages/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Análise de logs</li>
    </ol>
</nav>

<div class="row mt-5">
    <div class="col-md-4">
        <app-purchase-logs-card></app-purchase-logs-card>
    </div>
    <div class="col-md-4">
        <app-mercado-pago-logs-card></app-mercado-pago-logs-card>
    </div>
</div>