import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KycGetResponseDto } from 'src/app/dtos/kyc-get-response.dto';
import { KycService } from 'src/app/services/kyc.service';

@Component({
  selector: 'app-check-kyc-whitelist',
  templateUrl: './check-kyc-whitelist.component.html',
  styleUrls: ['./check-kyc-whitelist.component.scss']
})
export class CheckKycWhitelistComponent implements OnInit {

  resultList: KycGetResponseDto[];

  formSearch: FormGroup;

  currentPage: number = 1;
  itensPerPage: number = 20;

  constructor(
    private fb: FormBuilder,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private kycService: KycService,
  ) {
    this.formSearch = this.fb.group({
      term: ['']
    });
  }

  ngOnInit(): void {
    this.ngxSpinnerService.show();
    this.list();
  }

  list() {
    // this.kycService.listVerified().subscribe(
    //   success => this.runListSuccess(success),
    //   error => this.runError(error)

    // );
  }

  search() {
    const term: string = this.formSearch.controls.term.value;

    if (term) {
      const resultListFirstName = this.resultList.filter(a => a.firstName.toLowerCase().includes(term.toLowerCase()));
      const resultListLasttName = this.resultList.filter(a => a.lastName.toLowerCase().includes(term.toLowerCase()));
      const resultUnion = resultListFirstName.concat(resultListLasttName);
      this.resultList = resultUnion.filter((value, index) => resultUnion.indexOf(value) === index);
    } else {
      this.list();
    }
  }

  runListSuccess(success: KycGetResponseDto[]): void {
    this.resultList = success;
    this.ngxSpinnerService.hide();
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
  }
}