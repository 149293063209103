import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { RebuyUpdateStatusRequestDto } from "../dtos/rebuy-update-status-request.dto";
import { RebuyUpdateStatusResponseDto } from "../dtos/rebuy-update-status-response.dto";
import { BaseService } from "./base.service";
import { RebuyStatusEnum } from "../dtos/rebuy-status.enum";
import { RebuyGetResponseDto } from "../dtos/rebuy-get-response.dto";

@Injectable()
export class RebuyService extends BaseService {

    // url: string = environment.Apis.rebuy;

    // constructor(
    //     private httpClient: HttpClient,
    // ) {
    //     super();
    // }

    // listByStatus(status: RebuyStatusEnum): Observable<RebuyGetResponseDto[]> {
    //     return this.httpClient
    //         .get(`${this.url}/rebuy/status/${status}`, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }


    // count(status: RebuyStatusEnum): Observable<any> {
    //     return this.httpClient
    //         .get(`${this.url}/rebuy/status/${status}/count`, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }

    // getById(id: string): Observable<RebuyGetResponseDto> {
    //     return this.httpClient
    //         .get(`${this.url}/rebuy/${id}`, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }

    // updateStatus(_id: string, dto: RebuyUpdateStatusRequestDto): Observable<RebuyUpdateStatusResponseDto> {
    //     return this.httpClient
    //         .put(`${this.url}/rebuy/update-status/id/${_id}`, dto, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }
}