<nav class="navbar navbar-expand-lg navbar-light bg-white mb-3">
   
    <button class="nav-link btn btn-light ml-3" (click)="toggleSidebar()">
        <i class="fa fa-lg fa-bars bg-light "></i>
      </button>  

    <div [ngbCollapse]="navIsCollapsed" class="collapse navbar-collapse">
        

        <ul class="navbar-nav ml-auto">
            <li class="nav-item ml-auto">
                <div ngbDropdown class="d-inline-block">
                    <a href="javascript:void(0)" class="nav-link" id="ddUserOptions" ngbDropdownToggle>
                        <img class="img-profile rounded-circle mr-3" width="30" height="30"
                            src="assets/img/undraw_profile.svg" alt="colb profile">{{globalService.user?.email}}
                    </a>
                  
                    <div ngbDropdownMenu aria-labelledby="ddUserOptions">
                        <a href="javascript:void(0)" ngbDropdownItem [routerLink]="['/pages/user-reset-password', user.email]"><em class="fas fa-key"></em>
                            <span class="ml-3">Alterar senha</span></a>
                       
                        <a href="#" (click)= "logout()" ngbDropdownItem><em class="fas fa-sign-out-alt"></em><span
                                class="ml-3">Sair</span></a>
                    </div>
                </div>
            </li>
        </ul>
    </div>

</nav>