import { Component, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ClientService } from "src/app/services/client.service";
@Component({
  selector: 'app-operating-systems',
  templateUrl: './operating-systems.component.html',
  styleUrls: ['./operating-systems.component.scss'],
})
export class OperatingSystemsComponent implements OnInit {

  campaignOne: FormGroup; 
  android: number;
  ios:number
  
  constructor(
    private formBuilder: FormBuilder,
    private toastService: ToastrService,
    private clientService: ClientService,
  ) { }

  ngOnInit(): void {
    this.campaignOne = this.formBuilder.group({
      start: [''], 
      end: ['']
    });
  }

  onSubmit() {
    const startDate = this.campaignOne.value.start;
    const endDate = this.campaignOne.value.end; 
    console.log(startDate)
    if (!startDate || !endDate) return this.toastService.warning('Período de busca não preenchido corretamente')

    this.clientService.getAnalyticsSOReport(startDate,endDate).subscribe(
      result => {
        this.android = result.find(el => el.SO.toLowerCase() == 'android').quantity
        this.ios = result.find(el => el.SO.toLowerCase() == 'ios').quantity
        console.log(result)
      },error => this.toastService.error(error.message)
    )

  }

}
