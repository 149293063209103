import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RoleGetResponseDto } from 'src/app/dtos/role-get-response.dto';

@Injectable()
export class RolesServiceMock {

  roles: RoleGetResponseDto[] = [
    {
      _id: '1',
      name: 'Admin',
      description: '',
      functions:[]
    },
    {
      _id: '2',
      name: 'Backoffice Offers',
      description: '',
      functions:[]
    },
    {
      _id: '3',
      name: 'Backoffice Whitelister',
      description: '',
      functions:[]
    },
    {
      _id: '4',
      name: 'Backoffice Minter',
      description: '',
      functions:[]
    },
    {
      _id: '5',
      name: 'Backoffice Burnner',
      description: '',
      functions:[]
    }
  ];

  list(): Observable<RoleGetResponseDto[]> {
    return of(this.roles);  
  }

  getBy_id(_id: string): Observable<RoleGetResponseDto> {
    return of(this.roles.filter(r => r._id === _id)[0]);
  }
}