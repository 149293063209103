export enum FunctionEnum {
    user = 'user',

    role = 'role',

    swap = 'swap',

    registerOffers = 'registerOffers',

    collateralAcquisition = 'collateralAcquisition',

    kyc = 'kyc',

    usuarios = 'usuarios',

    rebuy = 'rebuy',

    admin = 'admin',
}