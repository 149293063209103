import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WalletGetResponseDto } from '../../dtos/wallet-get-response.dto';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-wallet-list',
  templateUrl: './wallet-list.component.html',
  styleUrls: ['./wallet-list.component.scss']
})
export class WalletListComponent implements OnInit {

  currentPage: number = 1;
  itensPerPage: number = 20;

  list: WalletGetResponseDto[] = [];
  term: string;

  deleteId: string;

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private walletService: WalletService,
  ) { }

  ngOnInit(): void {
    this.ngxSpinnerService.show();
    this.listWallets();
  }

  listWallets() {
    this.walletService.list().subscribe(
      success => this.runListWalletSuccess(success),
      error => this.runError(error)
    )
  }

  runListWalletSuccess(success: WalletGetResponseDto[]): void {
    this.list = success;
    this.ngxSpinnerService.hide();
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
  }

  selectIdDelete(id: string) {
    this.deleteId = id;
  }

  delete() {
    this.ngxSpinnerService.show();
    this.walletService.delete(this.deleteId).subscribe(
      success => this.runDeleteRolesSuccess(),
      error => this.runError(error)
    )
  }
  runDeleteRolesSuccess(): void {
    const toastr = this.toastrService.success("Wallet has been deleted", "Success", {
      progressBar: true
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {
        this.deleteId = null;
        this.listWallets()
        this.ngxSpinnerService.hide();
      })
    }
  }

}
