import { Component, OnInit } from '@angular/core';
import { IMercadoPagoTableInterface } from "src/app/interfaces/mercado-pago-table.interface";
import { LogsService } from "src/app/services/logs.service";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-mercado-pago-logs',
  templateUrl: './mercado-pago-logs.component.html',
})
export class MercadoPagoLogsComponent implements OnInit {


  term: string;

  currentPage: number = 1;
  itemsPerPage: number = 10;

  mercadoPagoTable: IMercadoPagoTableInterface[] = []

  isChecked: boolean = false;

  public arrayBoxChecked: IMercadoPagoTableInterface[] = [];

  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';


  constructor(
    private logsService: LogsService,
  ) { }

  ngOnInit(): void {
    this.getAllLogs();
  }

  async getAllLogs() {
    try {
      const response = await this.logsService.getMercadoPagoTableData()

      const reader = response.body.getReader();
      let done = false;
      let buffer = '';

      while (!done) {
        const { done: readerDone, value: chunk } = await reader.read();

        done = readerDone;

        if (chunk) {
          const textChunk = new TextDecoder().decode(chunk);
          buffer += textChunk;

          while (buffer.includes('\n')) {
            const jsonChunk = buffer.substring(0, buffer.indexOf('\n'));
            buffer = buffer.substring(buffer.indexOf('\n') + 1);

            const obj = JSON.parse(jsonChunk);
            this.mercadoPagoTable.push(obj)
          }
        }

      }
    } catch (error) {
      console.error('Ocorreu um erro:', error);
    }
  }

  selectAll() {
    this.isChecked = true;
    this.mercadoPagoTable.forEach(user => {
      this.arrayBoxChecked.push(user);
    })
  }

  selectedClient(event, data) {
    if (event.target.checked == true) {
      this.arrayBoxChecked.push(data);
    } else {
      if (this.arrayBoxChecked.length != 0) {
        let indexBox = this.arrayBoxChecked.indexOf(data);
        this.arrayBoxChecked.splice(indexBox, 1);
      }
    }
  }

  exportFluxoFinanceiro() {
    const workbook = XLSX.utils.book_new();

    workbook.Props = {};
    workbook.Props.Title = 'Relatório de Clientes Ativos';

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrayBoxChecked);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Fluxo Financeiro');

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

    this.saveAsExcelFile(excelBuffer, `fluxo-mercado-pago`);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION);

    this.arrayBoxChecked = [];
    this.isChecked = false;
  }
}
