<div class="card l-bg-blue-light mb-3">
    <div class="card-statistic-3 p-4">
        <div class="card-icon card-icon-large">
            <em class="fas fa-book-open"></em>
        </div>
        <div class="mb-4">
            <h5 class="card-title mb-0">Análise de logs</h5>
        </div>
        <div class="row align-items-center mb-2 d-flex">
            <div class="col-8">
                <h2 class="d-flex align-items-center mb-0 invisible">
                    0
                </h2>
            </div>
        </div>
        <div class="link-to pr-5">
            <a href="javascript:void(0)" [routerLink]="['/pages/purchase-logs']">
                <span>Analisar logs</span>
            </a>
        </div>
    </div>
</div>
