import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageUtil, LocalStorageKeys } from '../utils/localstorage-util';

@Injectable()
export class AuthenticateInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private spinnerService: NgxSpinnerService,
    ) { }

    localStorageUtil = new LocalStorageUtil();

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(error => {

            this.spinnerService.hide();

            if (error instanceof HttpErrorResponse) {

                if (error.status === 401) {
                    this.localStorageUtil.remove(LocalStorageKeys.user);
                    this.router.navigate(['/']);
                }
                if (error.status === 403) {
                    this.router.navigate(['/forbidden']);
                }
            }
            return throwError(error);
        }));
    }
}