import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { MessageRegisterRequestDto } from "../dtos/message-register-request.dto";
import { BaseService } from "./base.service";

@Injectable()
export class MessageService extends BaseService {

    // url: string = environment.Apis.message;

    // constructor(
    //     private httpClient: HttpClient,
    // ) {
    //     super();
    // }

    // register(address: string, dto: MessageRegisterRequestDto) {

    //     const body = {
    //         message: JSON.stringify(dto),
    //         address
    //     };

    //     return this.httpClient
    //         .post(`${this.url}/message`, body, this.authorizedHeader())
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.serviceError)
    //         );
    // }
}