import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientGetResponseDto } from '../../dtos/client-get-response.dto';
import { CashFlowService } from '../../services/cash-flow.service';
import { ClientService } from '../../services/client.service';
import moment from 'moment';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { FormatDate } from "src/app/utils/formatDate.util";
import { LogsService } from "src/app/services/logs.service";

@Component({
  selector: 'app-check-deposits',
  templateUrl: './check-deposits.component.html',
  styleUrls: ['./check-deposits.component.scss']
})
export class CheckDepositsComponent implements OnInit {

  resultList: ClientGetResponseDto[];

  term: string;

  currentPage: number = 1;
  itensPerPage: number = 20;

  FormatDate = FormatDate

  isChecked: boolean = false;

  todayDate: moment.Moment = moment(new Date());

  public arrayBoxChecked = [];
  arrayUserIncomplete = [];
  arrayUsersAlreadySheet = [];
  arrayNamePhoneChecked = [];

  countSuccess: number = 0;
  countNotSuccess: number = 0;

  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';

  arrayUsersPDF: { name: string, url: string }[] = []
  arrayUsersPremium: any[] = []


  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private clientService: ClientService,
    private cashFlowService: CashFlowService,
    private router: Router,
  ) { }


  // função iniciada ao carregar página
  ngOnInit(): void {
    this.ngxSpinnerService.show();
    this.list();
    this.listUserPdf()
  }

  listUserPdf() {
    this.clientService.listClientsPdf().subscribe(
      success => {
        this.arrayUsersPDF = success.pdfs
      },
      error => console.log(error)
    )
  }

  routeViewClients() {
    this.verifyIncompleteClients();

    setTimeout(() => {

      if (this.arrayBoxChecked.length > 0) {
        this.router.navigate(['/pages/view-customers-active/view-customers-exported'],
          {
            state: {
              users: this.arrayBoxChecked
            }
          });
      } else {
        this.toastrService.warning('Por favor, insira pelo menos um usuário válido para visualização', 'Usuário inexistente', {
          progressBar: true
        });
      }

    }, 500);

  }

  // lista clientes ativos
  list() {
    this.clientService.listAll().subscribe(
      success => {
        console.log(success.length)
        this.runListSuccess(success);
      },
      error => this.runError(error)
    );
  }

  // setando a variavel resultList com o resultado do banco de dados
  runListSuccess(success: ClientGetResponseDto[]): void {
    this.resultList = success.reverse();
    this.ngxSpinnerService.hide();
  }

  selectAll() {
    this.isChecked ? this.isChecked = false : this.isChecked = true;
    this.resultList.forEach(user => {
      this.arrayBoxChecked.push(user.email);
      this.arrayNamePhoneChecked.push({ name: user.name, phone: user.phone });
    })
  }

  selectedClient(event, userEmail: string, userName: string, userPhone: string) {
    if (event.target.checked == true) {
      this.arrayBoxChecked.push(userEmail);
      this.arrayNamePhoneChecked.push({ name: userName, phone: userPhone });
      console.log(this.arrayBoxChecked)
    } else {
      if (this.arrayBoxChecked.length != 0) {
        let indexBox = this.arrayBoxChecked.indexOf(userEmail);
        let indexName = this.arrayBoxChecked.indexOf({ name: userName, phone: userPhone });
        this.arrayBoxChecked.splice(indexBox, 1);
        this.arrayNamePhoneChecked.splice(indexName, 1);
      }
    }
  }

  exportNamePhone() {
    const workbook = XLSX.utils.book_new();

    workbook.Props = {};
    workbook.Props.Title = 'Número e telefone dos clientes ativos';

    workbook.SheetNames.push('Página 1');

    let wscols = [
      { width: 30 }, //col 1
      { width: 30 }, //col 2
    ];

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrayNamePhoneChecked);

    worksheet['!cols'] = wscols;

    workbook.Sheets['Página 1'] = worksheet;

    workbook.Sheets['Página 1']['A1'].v = 'Nome';
    workbook.Sheets['Página 1']['B1'].v = 'Telefone';

    XLSX.writeFile(workbook, 'nome-telefone.xlsx');
  }

  verifyIncompleteClients() {
    this.arrayBoxChecked.forEach(userEmail => {
      this.cashFlowService.get(userEmail).subscribe(
        success => {
          console.log('success', success)
          if (!success || !success.report || success.report.length <= 0) {
            this.insertUserIncomplete(userEmail);
          }
        },
        error => {
          this.insertUserIncomplete(userEmail);
        });
    });
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
  }

  showMessageIncompletUser() {
    this.toastrService.error(`${this.arrayUserIncomplete.length} usuários não completaram o projeto de vida.\nPor esse motivo não serão exportados\n`, 'Fluxo inexistente!', {
      progressBar: true
    });
    this.arrayUserIncomplete = [];
  }

  insertUserIncomplete(userEmail) {
    this.arrayUserIncomplete.push(userEmail);

    let index = this.arrayBoxChecked.indexOf(userEmail);
    this.arrayBoxChecked.splice(index, 1);

    setTimeout(() => {
      if (this.arrayUserIncomplete.length - 1 == this.countNotSuccess) {
        this.showMessageIncompletUser();
      } else {
        this.countNotSuccess++;
      }
    }, 300);
  }

  formatDate(date: Date) {
    return new Date(date).toLocaleDateString('pt-BR')
  }

  async exportUserPDF(userEmail: string) {
    const fileUrl = 'https://walkr-app-pdfs.s3.amazonaws.com/MeuProjetoDeVida_' + userEmail + '.pdf';
    window.open(fileUrl, '_blank');
  }

  userHavePdf(email: string) {
    if (this.arrayUsersPDF.filter(el => el.name.includes(email)).length > 0) {
      return true
    }
    return false
  }

}
