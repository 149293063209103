import { Component, OnInit } from '@angular/core';
import { AdvisorService } from 'src/app/services/advisor.service';

@Component({
  selector: 'advisor-dashboard-card',
  templateUrl: './advisor-dashboard-card.component.html',
  styleUrls: ['./advisor-dashboard-card.component.scss']
})
export class AdvisorDashboardCardComponent implements OnInit {

  count: number = 0;

  constructor(
    private advisorService: AdvisorService
  ) { }

  ngOnInit(): void {
    this.advisorService.list().subscribe(
      success => this.count = success.data.length || 0,
      error => console.error(error)
    )
  }

}
