import { Injectable } from '@angular/core';
import { ethers } from 'ethers';
import { GlobalService } from './global.service';
import * as TokenJson from '../../assets/contracts/Token.json';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  tokenContract: ethers.Contract;

  constructor(private globals: GlobalService) {}

  public async initContract(contractAddress: string,) {
    const provider = this.globals.walletProvider;
    return new ethers.Contract(
      contractAddress,
      TokenJson.abi,
      provider
    );
}

   async balanceOf(address: string): Promise<number> {
    const balanceBn = await this.tokenContract.balanceOf(address);
    return Number(ethers.utils.formatEther(balanceBn));
  }
}
