<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">KYC</li>
        <li class="breadcrumb-item active" aria-current="page">Check KYC</li>
    </ol>
</nav>

<div class="card mb-3">
    <div class="card-body">
        <form (ngSubmit)="search()" [formGroup]="formSearch">
            <div class="form-group">
                <label for="term">Search</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Full text search" formControlName="term">
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-secondary" [disabled]="formSearch.invalid">
                            <em class="fas fa-search"></em> Search
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="card">
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-sm table-hover table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col" class="text-center">#</th>
                        <th scope="col">Name</th>
                        <th scope="col" class="text-center">Date of request</th>
                        <th scope="col" class="text-center">Country of residence</th>
                        <th scope="col" class="text-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of resultList | paginate: { itemsPerPage: itensPerPage, currentPage: currentPage }; let i= index">
                        <td class="text-center">{{i+1}}</td>
                        <td>{{item.lastName}}, {{item.firstName}}</td>
                        <td class="text-center">{{item.createdAt.slice(0,10)}}</td>
                        <td class="text-center">{{item.country}}</td>
                        <td class="text-center">
                            {{item.status | titlecase}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls (pageChange)="currentPage = $event" previousLabel="Previous" nextLabel="Next">
            </pagination-controls>
        </div>
    </div>
</div>