import { TfaResolve } from '../resolvers/tfa.resolve';
import { UserResolve } from '../resolvers/user.resolve';
import { RolesServiceMock } from '../services/mocks/roles.service.mock';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { CheckDepositsComponent } from "./check-deposits/check-deposits.component";
import { PagesComponent } from "./pages.component";
import { PagesRoutingModule } from "./pages.routing";
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavModule } from "../nav/nav.module";
import { NgxPaginationModule } from "ngx-pagination";
import { CheckDepositsDetailComponent } from './check-deposits-detail/check-deposits-detail.component';
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserTfaComponent } from './user-tfa/user-tfa.component';
import { UserResetPasswordComponent } from './user-reset-password/user-reset-password.component';
import { NgxMaskModule } from 'ngx-mask'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserNewComponent } from './user-new/user-new.component';
import { CheckRebuyComponent } from './check-rebuy/check-rebuy.component';
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { CheckKycComponent } from './check-kyc/check-kyc.component';
import { CheckKycDetailsComponent } from './check-kyc-details/check-kyc-details.component';
import { RebuyDashboardCardComponent } from './components/rebuy-dashboard-card/rebuy-dashboard-card.component';
import { DepositDashboardCardComponent } from './components/deposit-dashboard-card/deposit-dashboard-card.component';
import { KycDashboardCardComponent } from './components/kyc-dashboard-card/kyc-dashboard-card.component';
import { DepositService } from '../services/deposits.service';
import { AuthService } from '../services/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticateInterceptor } from '../interceptors/autenticacao.interceptor';
import { TokenService } from '../services/token.service';
import { StorageService } from '../services/storage.service';
import { MessageService } from '../services/message.service';
import { KycService } from '../services/kyc.service';
import { CcoinService } from '../services/ccoin.service';
import { RebuyService } from '../services/rebuy.service';
import { OffersService } from '../services/offers.service';
import { WhitelistService } from '../services/whitelist.service';
import { CheckKycWhitelistComponent } from './check-kyc-whitelist/check-kyc-whitelist.component';
import { UserService } from '../services/user.service';
import { RoleService } from '../services/role.service';
import { CheckRebuyDetailComponent } from './check-rebuy-detail/check-rebuy-detail.component';
import { UserEmailResolve } from '../resolvers/user-email.resolve';
import { RolesComponent } from './roles/roles.component';
import { RolesNewComponent } from './roles-new/roles-new.component';
import { RolesEditComponent } from './roles-edit/roles-edit.component';
import { RolesResolve } from '../resolvers/roles.resolve';
import { TfaService } from '../services/tfa.service';
import { QRCodeModule } from 'angularx-qrcode';
import { OfferContractService } from '../services/offerContract.service';
import { SidebarService } from '../services/sidebar.service';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ClientService } from '../services/client.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CPFPipe } from '../pipes/CPFPipe';
import { PhonePipe } from '../pipes/PhonePipe';
import { WalletListComponent } from './wallet-list/wallet-list.component';
import { WalletNewComponent } from './wallet-new/wallet-new.component';
import { WalletEditComponent } from './wallet-edit/wallet-edit.component';
import { WalletResolve } from '../resolvers/wallet.resolve';
import { WalletService } from '../services/wallet.service';
import { CashFlowService } from '../services/cash-flow.service';
import { ViewClientsExportedComponent } from './view-customers-exported/view-customers-exported.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from '../functions/CustomPaginatorConfiguration';
import { RvcDashboardCardComponent } from './components/rvc-dashboard-card/rvc-dashboard-card.component';
import { RemoveCustomersComponent } from './remove-customers/remove-customers.component';
import { PgcDashboardCardComponent } from './components/pgc-dashboard-card/pgc-dashboard-card.component';
import { ProgressCustomersComponent } from './progress-customers/progress-customers.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { LogsDashboardCardComponent } from './components/logs-dashboard-card/logs-dashboard-card.component';
import { LogsService } from "../services/logs.service";
import { LogsComponent } from "./logs/logs.component";
import { PurchaseLogsComponent } from "./purchase-logs/purchase-logs.component";
import { PurchaseLogsCardComponent } from './components/purchase-logs-card/purchase-logs-card.component';
import { MercadoPagoLogsCardComponent } from './components/mercado-pago-logs-card/mercado-pago-logs-card.component';
import { MercadoPagoLogsComponent } from './mercado-pago-logs/mercado-pago-logs.component';
import { TableSizeSelectorComponent } from './components/table-size-selector/table-size-selector.component';
import { AdvisorService } from '../services/advisor.service';
import { PushService } from "../services/push.service";
import { AdvisorResumeComponent } from './advisor-resume/advisor-resume.component';
import { AdvisorDashboardCardComponent } from './components/advisor-dashboard-card/advisor-dashboard-card.component';
import { BuysGoldResumeComponent } from './buys-gold-resume/buys-gold-resume.component';
import { OperatingSystemsComponent } from "./operating-systems/operating-systems.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";

export const httpInterceptorsProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticateInterceptor, multi: true },
];

@NgModule({
    declarations: [
        PagesComponent,
        CheckDepositsComponent,
        CheckDepositsDetailComponent,
        DashboardComponent,
        UserListComponent,
        UserEditComponent,
        UserTfaComponent,
        UserResetPasswordComponent,
        UserNewComponent,
        CheckRebuyComponent,
        CheckKycComponent,
        CheckKycDetailsComponent,
        CheckKycWhitelistComponent,
        RebuyDashboardCardComponent,
        DepositDashboardCardComponent,
        KycDashboardCardComponent,
        CheckRebuyDetailComponent,
        RolesComponent,
        RolesNewComponent,
        RolesEditComponent,
        SidebarComponent,
        AdvisorResumeComponent,
        BuysGoldResumeComponent,

        CPFPipe,
        PhonePipe,
        WalletListComponent,
        WalletNewComponent,
        WalletEditComponent,
        ViewClientsExportedComponent,
        RvcDashboardCardComponent,
        RemoveCustomersComponent,
        PgcDashboardCardComponent,
        ProgressCustomersComponent,
        NotificationsComponent,
        OperatingSystemsComponent,

        LogsDashboardCardComponent,
        LogsComponent,
        PurchaseLogsComponent,
        PurchaseLogsCardComponent,
        MercadoPagoLogsCardComponent,
        MercadoPagoLogsComponent,
        TableSizeSelectorComponent,
        AdvisorDashboardCardComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        PagesRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgbTooltipModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-left',
        }),
        NgxPaginationModule,
        MatPaginatorModule,
        SweetAlert2Module,
        NavModule,
        NgxMaskModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        QRCodeModule,
        NgApexchartsModule,
        Ng2SearchPipeModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,

    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        httpInterceptorsProviders,
        RolesServiceMock,
        UserResolve,
        RolesResolve,
        TfaResolve,
        TfaService,
        KycService,
        DepositService,
        AuthService,
        TokenService,
        StorageService,
        OfferContractService,
        MessageService,
        CcoinService,
        RebuyService,
        OffersService,
        WhitelistService,
        UserService,
        RoleService,
        UserEmailResolve,
        SidebarService,
        ClientService,
        WalletResolve,
        WalletService,
        CashFlowService,
        LogsService,
        AdvisorService,
        PushService,
        { provide: MatPaginatorIntl, useValue: CustomPaginator() }
    ]
})
export class PagesModule { }