<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">Dashboard</li>
    <li class="breadcrumb-item" aria-current="page">
      <a [routerLink]="['/pages/wallet']">Carteira</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Editar carteira</li>
  </ol>
</nav>

<div class="card mb-3 col-sm-12 col-md-4 offset-md-4">
  <div class="card-body">
    <form (ngSubmit)="update()" [formGroup]="form" class="row">

      <input type="hidden" class="form-control" formControlName="_id">

      <div class="form-group  col-md-12">
        <label>Taxa</label>
        <input type="number" class="form-control" formControlName="taxa">
        <span class="text-danger" *ngIf="form.controls.taxa.invalid && form.controls.taxa.dirty">
          <p *ngIf="form.controls.nome.errors.required">A taxa é requerido!</p>
        </span>
      </div>
      
      <div class="form-group  col-md-12">
        <label>Nome</label>
        <input type="text" class="form-control" formControlName="nome">
        <span class="text-danger" *ngIf="form.controls.nome.invalid && form.controls.nome.dirty">
          <p *ngIf="form.controls.nome.errors.required">O nome é requerido!</p>
          <p *ngIf="form.controls.nome.errors.rangeLength">O nome precisa ter mais de 3 caracteres!</p>
        </span>
      </div>
      <div class="form-group  col-md-12">
        <label>Pós fixado</label>
        <input type="number" class="form-control" formControlName="posFixado">
        <span class="text-danger" *ngIf="form.controls.posFixado.invalid && form.controls.posFixado.dirty">
          <p *ngIf="form.controls.posFixado.errors.required">Pós fixado é requerido!</p>
        </span>
      </div>
      <div class="form-group  col-md-12">
        <label>Pré fixado</label>
        <input type="number" class="form-control" formControlName="preFixado">
        <span class="text-danger" *ngIf="form.controls.preFixado.invalid && form.controls.preFixado.dirty">
          <p *ngIf="form.controls.preFixado.errors.required">Pré fixado é requerido!</p>
        </span>
      </div>
      <div class="form-group  col-md-12">
        <label>Inflação</label>
        <input type="number" class="form-control" formControlName="inflacao">
        <span class="text-danger" *ngIf="form.controls.inflacao.invalid && form.controls.inflacao.dirty">
          <p *ngIf="form.controls.inflacao.errors.required">Inflação é requerido!</p>
        </span>
      </div>
      <div class="form-group  col-md-12">
        <label>RF Global</label>
        <input type="number" class="form-control" formControlName="rfGlobal">
        <span class="text-danger" *ngIf="form.controls.rfGlobal.invalid && form.controls.rfGlobal.dirty">
          <p *ngIf="form.controls.rfGlobal.errors.required">RF Global é requerido!</p>
        </span>
      </div>
      <div class="form-group  col-md-12">
        <label>Multimercado</label>
        <input type="number" class="form-control" formControlName="multimercado">
        <span class="text-danger" *ngIf="form.controls.multimercado.invalid && form.controls.multimercado.dirty">
          <p *ngIf="form.controls.multimercado.errors.required">Multimercado é requerido!</p>
        </span>
      </div>
      <div class="form-group  col-md-12">
        <label>RV Brasil</label>
        <input type="number" class="form-control" formControlName="rvBrasil">
        <span class="text-danger" *ngIf="form.controls.rvBrasil.invalid && form.controls.rvBrasil.dirty">
          <p *ngIf="form.controls.rvBrasil.errors.required">RV Brasil é requerido!</p>
        </span>
      </div>
      <div class="form-group  col-md-12">
        <label>RV Global</label>
        <input type="number" class="form-control" formControlName="rvGlobal">
        <span class="text-danger" *ngIf="form.controls.rvGlobal.invalid && form.controls.rvGlobal.dirty">
          <p *ngIf="form.controls.rvGlobal.errors.required">RV Global é requerido!</p>
        </span>
      </div>
      <div class="form-group  col-md-12">
        <label>Alternativos</label>
        <input type="number" class="form-control" formControlName="alternativos">
        <span class="text-danger" *ngIf="form.controls.alternativos.invalid && form.controls.alternativos.dirty">
          <p *ngIf="form.controls.alternativos.errors.required">Alterntivos é requerido!</p>
        </span>
      </div>
      <div class="form-group col-sm-12">
        <button type="submit" class="btn button-color btn-block" [disabled]="form.invalid">Atualizar carteira</button>
      </div>
    </form>
  </div>
</div>