import { Injectable } from '@angular/core';
import { ethers } from 'ethers';
import { GlobalService } from './global.service';
import * as offerContractJSON from '../../assets/contracts/AssetOffering.json';

@Injectable({
  providedIn: 'root',
})
export class OfferContractService {
  offerContract: ethers.Contract;

  constructor() {}

  initContract(
    contractAddress: string,
    walletOrProvider: ethers.Wallet | ethers.providers.Provider
  ) {
    this.offerContract = new ethers.Contract(
      contractAddress,
      offerContractJSON.abi,
      walletOrProvider
    );
  }

  async totalActiveBids(): Promise<number> {
    const valueBn = await this.offerContract.totalActiveBids();
    return Number(ethers.BigNumber.from(valueBn).toString());
  }

  
  async totalBids(): Promise<number> {
    const valueBn = await this.offerContract.totalBids();
    return Number(ethers.BigNumber.from(valueBn).toString());
  }

  async totalProcessedBids(): Promise<number> {
    const valueBn = await this.offerContract.totalProcessedBids();
    return Number(ethers.BigNumber.from(valueBn).toString());
  }

  async assetOfferingExpirationDate(): Promise<number> {
    const valueBn = await this.offerContract.assetOfferingExpirationDate();
    return Number(ethers.BigNumber.from(valueBn).toString());
  }

  async assetTokenContractAddress(){
    return await this.offerContract.assetTokenAddress();
  }
}
