<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a [routerLink]="['/pages/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Notificações</li>
    </ol>
</nav>
<div class="card card-radius mb-3">
    <div class="card-body">

        <div class="form-group">
            <label for="term">Buscar</label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Digite o texto para buscar" [(ngModel)]="term">
            </div>
        </div>
    </div>
</div>
<div class="card mb-3">
    <div class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-sm table-hover">
                <thead>
                    <tr>
                        <th scope="col">Título</th>
                        <th class="border-left" scope="col">Texto</th>
                        <th class="border-left" scope="col">Filtro</th>
                        <th class="border-left" scope="col">Data</th>
                    </tr>
                </thead>
                <br>
                <tbody>
                    <tr *ngFor="let notification of notifications">
                        <td>{{notification.title}}</td>
                        <td>{{notification.message}}</td>
                        <td>{{formatFilterText(notification.filter)}}</td>
                        <td>{{formatHour(notification.createdAt)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls (pageChange)="currentPage = $event" previousLabel="Anterior" nextLabel="Próximo">
            </pagination-controls>
        </div>
    </div>
</div>

<div class=" d-flex justify-content-end">
    <button (click)="showModalSendPushNotification(myModal)" class="btn btn-primary">Criar Notificação</button>
</div>

<ng-template #myModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Enviar nova notificação</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <h5>Dados da notificação</h5>
        <form (submit)="!title || !message || checkEveryFields() ? showRequiredMessage() : showConfirmation()"
            class="form-group mt-2">
            <label class="form-label">Título</label>
            <input (change)="changeTitle($event)" [value]="title" required class="form-control" type="text">
            <label class="form-label">Texto</label>
            <textarea (change)="changeMessage($event)" [value]="message" required class="form-control"
                name="text-notification" id="text-notification" cols="30" rows="10"></textarea>
            <!-- <label class="form-label" for="category">Categoria</label>
            <br>
            <input type="radio" name="category" id="check1"> Institucional
            <input type="radio" name="category" id="check2"> Financeiro -->
            <label class="form-label mb-3 mt-3">Filtros</label>

            <div class="d-flex">
                <div class="mb-3 d-flex align-items-center ">
                    <input (change)="fieldsChecked.premium = !fieldsChecked.premium; fieldsChecked.specificUsers = false" type="radio" name="filter" id="">
                    <label class="ml-2 mb-0" for="">WalkrApp+</label>
                </div>
                <div class="mb-3 d-flex align-items-center ml-3">
                    <input (change)="fieldsChecked.noPremium = !fieldsChecked.noPremium; fieldsChecked.specificUsers = false" type="radio" name="filter"
                        id="">
                    <label class="ml-2 mb-0" for="">Não WalkrApp+</label>
                </div>
                <div class="mb-3 d-flex align-items-center ml-3">
                    <input (change)="fieldsChecked.everyone = !fieldsChecked.everyone; fieldsChecked.specificUsers = false" type="radio" name="filter" id="">
                    <label class="ml-2 mb-0" for="">Todos</label>
                </div>
                <div class="mb-3 d-flex align-items-center ml-3">
                    <input (change)="fieldsChecked.specificUsers = !fieldsChecked.specificUsers" type="radio"
                        name="filter" id="">
                    <label class="ml-2 mb-0" for="">Usuário específico</label>
                </div>
            </div>

            <p [className]="requiredMessage ? ' text-danger' : 'd-none'">Preencha todos os campos</p>
            <button type="submit" [className]="displayNone ? 'mt-2 btn btn-outline-primary' : 'd-none' ">Enviar</button>

            <input class="ml-3" *ngIf="fieldsChecked.specificUsers" (change)="changeSpecificUsers($event)" [value]="specificUsers"
                type="text" placeholder="Insira os ids dos usuários separados por vírgula">
        </form>
    </div>
    <div [className]="displayNone ?  'modal-footer d-none' : 'modal-footer d-block'">
        <p class="text-danger">Você tem certeza que deseja enviar a notificação?</p>
        <button type="button" class="btn btn-outline-dark" aria-label="Cancel" (click)="modal.dismiss('Cancel click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" class="btn btn-outline-primary"
            (click)="sendNotification(); modal.close('Save click')">Enviar</button>
    </div>
</ng-template>
