import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PushService } from "src/app/services/push.service";
import { RegisterNotificationDTO } from "src/app/dtos/register-notification.dto";
import { ToastrService } from "ngx-toastr";
import { FormatDate, FormatHour } from "src/app/utils/formatDate.util";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  displayNone: boolean = true;

  closeResult: string;

  term: string;

  currentPage: number = 1;
  itensPerPage: number = 20;

  title: string = "";
  message: string = "";

  requiredMessage: boolean = false;

  fieldsChecked = {
    premium: false,
    noPremium: false,
    everyone: false,
    specificUsers: false
  }

  specificUsers: string = ""

  notifications: any[] = []

  formatHour = FormatHour

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private pushService: PushService,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
    this.ngxSpinnerService.show();
    this.list();
  }

  list() {
    this.pushService.getNotifications().subscribe(result => { console.log(result); this.notifications = result })
    this.ngxSpinnerService.hide()
  }

  showModalSendPushNotification(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`
      this.displayNone = true;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.displayNone = true;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  showConfirmation() {
    this.displayNone = false;
  }

  changeTitle(e: any) { this.title = e.target.value }
  changeMessage(e: any) { this.message = e.target.value }
  changeSpecificUsers(e: any) { this.specificUsers = e.target.value }

  async sendNotification() {

    this.ngxSpinnerService.show()

    let notification: RegisterNotificationDTO = {
      title: this.title,
      message: this.message,
      filter: this.fieldsChecked,
      usersId: this.specificUsers
    }

    this.pushService.sendNotification(notification).subscribe(
      result => {
        this.toastService.success("Notificação enviada com sucesso")
        this.list()
      }, error => {
        this.toastService.error(`Usuários não encontrados na categoria ${Object.entries(this.fieldsChecked).find(el => el[1] == true)[0]}`)
      },
      () => {
        this.resetForm()
        this.ngxSpinnerService.hide()
      }
    )

  }

  showRequiredMessage() {
    this.requiredMessage = !this.requiredMessage;
  }

  resetForm() {
    this.requiredMessage = false
    this.title = "";
    this.message = "";
    this.fieldsChecked = {
      premium: false,
      noPremium: false,
      everyone: false,
      specificUsers: false
    }
  }

  checkEveryFields() {
    return Object.values(this.fieldsChecked).every(el => el == false)
  }

  formatFilterText(filter: string) {
    switch (filter) {
      case 'noPremium': return "NÃO WalkrApp+";
      case 'premium': return "WalkrApp+";
      case 'everyone': return "Todos";
    }
  }

}
