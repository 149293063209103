import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { DepositChangeResponseDto } from '../dtos/depositChange-response.dto';
import { DepositChangeBonusRequestDto } from '../dtos/depositChangeBonus-request.dto';
import { MintNftCreateDto } from '../dtos/mintNft-create.dto';
import { MintNftGetDto } from '../dtos/mintNft-get.dto';
import { OffersListGetResponseDto } from '../dtos/offer-list-get-response.dto';
import { OffersCreateRequestDto } from '../dtos/offers-create-request.dto';
import { OffersDeployRequestDto } from '../dtos/offers-deploy-request.dto';
import { OffersDeployResponseDto } from '../dtos/offers-deploy-response.dto';
import { OffersGetResponseDto } from '../dtos/offers-get-response.dto';
import { OffersSetupRequestDto } from '../dtos/offers-setup-request.dto';
import { OffersSetupResponseDto } from '../dtos/offers-setup-response.dto';
import { OffersTrackStateResponseDto } from '../dtos/offers-track-state-response.dto';
import { OffersUpdateStatusRequestDto } from '../dtos/offers-update-status-request.dto';
import { OffersUpdateStatusResponseDto } from '../dtos/offers-update-status-response.dto';
import { BaseService } from './base.service';

@Injectable()
export class OffersService extends BaseService {
  // url: string = environment.Apis.offers;
  // urlCore: string = environment.Apis.core;

  // constructor(private httpClient: HttpClient) {
  //   super();
  // }

  // create(dto: OffersCreateRequestDto): Observable<OffersCreateRequestDto> {
  //   return this.httpClient
  //     .post(`${this.url}/offer`, dto, this.authorizedHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // createNft(dto: any): Observable<any> {
  //   return this.httpClient
  //     .post(`${this.url}/nft`, dto, this.authorizedHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // listNft(): Observable<MintNftGetDto[]> {
  //   return this.httpClient
  //     .get(`${this.url}/nft`, this.anonymousHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // nftById(_id: string): Observable<MintNftGetDto> {
  //   return this.httpClient
  //     .get(`${this.url}/nft/${_id}`, this.authorizedHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }
  // mintNft(dto: MintNftCreateDto): Observable<MintNftGetDto> {
  //   return this.httpClient
  //     .post(`${this.urlCore}/nft/mintNFT`, dto, this.authorizedHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }
  // list(): Observable<OffersListGetResponseDto[]> {
  //   return this.httpClient
  //     .get(`${this.url}/offer`, this.anonymousHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // listProcessing(): Observable<OffersListGetResponseDto[]> {
  //   return this.httpClient
  //     .get(`${this.url}/offer/list-status-processing`, this.anonymousHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // getById(_id: string): Observable<OffersGetResponseDto> {
  //   return this.httpClient
  //     .get(`${this.url}/offer/id/${_id}`, this.anonymousHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // deploy(dto: OffersDeployRequestDto): Observable<OffersDeployResponseDto> {
  //   return this.httpClient
  //     .post(`${this.urlCore}/offer/deploy`, dto, this.authorizedHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // updateStatus(
  //   _id: string,
  //   dto: OffersUpdateStatusRequestDto
  // ): Observable<OffersUpdateStatusResponseDto> {
  //   return this.httpClient
  //     .put(
  //       `${this.url}/offer/update-status/id/${_id}`,
  //       dto,
  //       this.authorizedHeader()
  //     )
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // setup(dto: OffersSetupRequestDto): Observable<OffersSetupResponseDto> {
  //   return this.httpClient
  //     .post(`${this.urlCore}/offer/setup`, dto, this.authorizedHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // changeDepositBonus(dto: DepositChangeBonusRequestDto): Observable<DepositChangeResponseDto> {
  //   return this.httpClient
  //     .post(`${this.urlCore}/depositBonus/change-deposit-bonus-address`, dto, this.authorizedHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // preprocessBids(
  //   dto: OffersSetupRequestDto
  // ): Observable<OffersSetupResponseDto> {
  //   return this.httpClient
  //     .post(
  //       `${this.urlCore}/offer/preprocess-bids`,
  //       dto,
  //       this.authorizedHeader()
  //     )
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // returnFactor(address: string) {
  //   return this.httpClient
  //     .post(
  //       `${this.urlCore}/offer/process-return-factor`,
  //       address,
  //       this.authorizedHeader()
  //     )
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // inputUnderlyingAsset(address: string) {
  //   return this.httpClient
  //     .post(
  //       `${this.urlCore}/offer/input-underlying-asset`,
  //       { address: address },
  //       this.authorizedHeader()
  //     )
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // processBid(dto: {
  //   address: string;
  //   steps: number;
  // }): Observable<OffersSetupResponseDto> {
  //   return this.httpClient
  //     .post(`${this.urlCore}/offer/process-bids`, dto, this.authorizedHeader())
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // completeOffer(contractAddress: string, manager: boolean) {
  //   return this.httpClient
  //     .post(
  //       `${this.urlCore}/offer/complete-offer`,
  //       { contractAddress, manager },
  //       this.authorizedHeader()
  //     )
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // trackState(offerAddress: string): Observable<OffersTrackStateResponseDto> {
  //   return this.httpClient
  //     .post(
  //       `${this.url}/offer/track-state/${offerAddress}`,
  //       {},
  //       this.authorizedHeader()
  //     )
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }

  // setupOfferTracker(offerAddress: string): Observable<OffersSetupResponseDto> {
  //   return this.httpClient
  //     .post(
  //       `${this.urlCore}/offer/setup-offer-tracker/`,
  //       {address: offerAddress},
  //       this.authorizedHeader()
  //     )
  //     .pipe(map(this.extractData), catchError(this.serviceError));
  // }
}
